
<template>
  <div class="flex flex-wrap w-full items-center justify-center">
    <pagination v-if="perPage" :total-items="totalItems" :currentPage="currentPage" :perPage="perPage"
      @page-changed="pageChanged($event)" />

    <list-sort-control v-if="sort && sortOptions" v-model:asc="sort.ascending" :options="sortOptions"
      v-model:sortBy="sort.sortBy" />
  </div>
  <div class="overflow-hidden w-full">
    <div class="top-0 left-0 w-full h-1 bg-primary-100 overflow-hidden relative">
      <div class="bg-indigo-500 h-1 absolute"
        :class="{ 'animate-none': !loading, 'animate-progressLinearInc': loading }"></div>
      <div class="bg-indigo-500 h-1 absolute"
        :class="{ 'animate-none': !loading, 'animate-progressLinearDec': loading }"></div>
    </div>
    <div
      class="grid justify-center flex-wrap gap-1 xs2:gap-4 divide-gray-200 dark:divide-gray-600 lg:grid-cols-cards-auto-large grid-cols-cards-auto-small pb-2"
      :class="{
        'animate-pulse': loading
      }">
      <div v-for="item in items" :key="item.id" :class="itemClasses">
        <template v-if="item.link">
          <router-link :to="item.link"
            class="block hover:bg-gray-100 dark:hover:bg-gray-900 dark:hover:bg-opacity-50 h-full">
            <list-item v-if="!slotIsListItem">
              <template v-for="(_, name) in $slots" v-slot:[nameString(name)]="slotData">
                <slot :name="name" v-bind="{ ...slotData, item }" />
              </template>
            </list-item>
            <slot v-else v-bind="{ item }" />
          </router-link>
        </template>
        <template v-else>
          <list-item v-if="!slotIsListItem">
            <template v-for="(_, name) in $slots" v-slot:[nameString(name)]="slotData">
              <slot :name="name" v-bind="{ ...slotData, item }" />
            </template>
          </list-item>
          <slot v-else v-bind="{ item }" />
        </template>
      </div>
    </div>
    <div class="text-center py-4 my-0.5" v-if="items.length == 0">
      <span class="text-center font-bold text-xl">{{ t("no_results") }}</span>
    </div>
  </div>

  <div class="flex flex-wrap w-full items-center justify-center">
    <pagination v-if="perPage" :total-items="totalItems" :currentPage="currentPage" :perPage="perPage"
      @page-changed="pageChanged($event)" />

    <list-sort-control v-if="sort && sortOptions" v-model:asc="sort.ascending" :options="sortOptions"
      v-model:sortBy="sort.sortBy" />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import Pagination from "./Pagination.vue";
import ListItem from "./ListItem.vue";
import ListSortControl from "./ListSortControl.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "GridList",
  components: {
    Pagination,
    ListItem,
    ListSortControl,
  },
  props: {
    items: {
      type: Object,
      required: true,
    },
    totalItems: {
      type: Number,
    },
    page: {
      type: Number,
    },
    perPage: {
      type: Number,
    },
    currentPage: {
      type: Number,
    },
    itemClasses: {
      type: String,
    },
    sort: {
      type: Object,
    },
    sortOptions: {
      type: Object as PropType<Array<any>>,
    },
    loading: {
      type: Boolean,
    }
  },
  emits: ["pageChanged"],
  setup(props, { emit, slots }) {
    const pageChanged = (page: number) => {
      emit("pageChanged", page);
    };

    const { t } = useI18n();

    const slotIsListItem =
      Object.entries(slots).length == 1 && Object.keys(slots)[0] === "default";

    const nameString = (
      v: any
    ): "icons" | "name" | "image" | "sub" | "extra" => {
      return v.toString();
    };
    return { pageChanged, t, slotIsListItem, nameString };
  },
});
</script>