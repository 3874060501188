<template>
  <template v-if="isCost">
    <span class="flex" :class="resourceBackgroundClass">
      <resource-thumb class="my-auto" width="16" height="16" :id="cost.resource_id" />
      <yuki-text
        class="flex items-center text-md px-1"
        skey="name"
        :id="cost.resource_id"
        category="materials"
      />
    </span>
    <span
      class="inline-flex items-center ml-auto font-bold tabular-nums"
      :class="resourceBackgroundClass"
    >{{ abbreviateNumber(cost.amount) }}</span>
  </template>
  <template v-if="isRequirement">
    <template v-if="requirement.requirement_type == 'BuildingLevel'">
      <building-thumb class="my-auto" width="32" height="32" :id="requirement.requirement_id" />
      <yuki-text
        class="text-sm pl-1"
        skey="name"
        :id="requirement.requirement_id"
        category="buildings"
      />
    </template>
    <template v-if="requirement.requirement_type == 'ResearchLevel'">
      <research-thumb class="my-auto" width="32" height="32" :id="requirement.requirement_id" />
      <yuki-text
        class="text-sm px-1 text-center"
        skey="name"
        :id="requirement.requirement_id"
        category="research"
      />
    </template>
    <div class="flex justify-between px-1 text-sm">
      <span>{{ t("building.level") }}</span>
      <span class="ml-2">{{ requirement.requirement_level }}</span>
    </div>
  </template>
  <template v-if="isReward">
    <span class="flex" :class="resourceBackgroundClass">
      <resource-thumb
        v-if="reward.type == 3"
        class="my-auto"
        width="16"
        height="16"
        :id="reward.resource_id"
      />
      <yuki-text
        class="flex items-center text-md px-1"
        skey="name"
        :id="reward.resource_id"
        category="materials"
      />
    </span>
    <span
      class="inline-flex items-center ml-auto font-bold tabular-nums"
      :class="resourceBackgroundClass"
    >{{ abbreviateNumber(cost.amount) }}</span>
  </template>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import { Reward, Requirement, BuildCost, Rarity } from "~/lib/yuki/models";

import BuildingThumb from "../thumbs/BuildingThumb.vue";
import ResearchThumb from "../thumbs/ResearchThumb.vue";
import ResourceThumb from "../thumbs/ResourceThumb.vue";
import MaybeLink from "../ui/MaybeLink.vue";
import YukiText from "../ui/YukiText.vue";

import { abbreviateNumber, formatGroupedNumber } from "~/lib/formatters";

import { useI18n } from "vue-i18n";
import { useYukiDataStore } from "~/stores";

export default defineComponent({
  name: "ItemLineGrid",
  components: {
    "yuki-text": YukiText,
    "maybe-link": MaybeLink,
    "building-thumb": BuildingThumb,
    "research-thumb": ResearchThumb,
    "resource-thumb": ResourceThumb,
  },
  props: {
    left: {
      type: Boolean,
    },
    item: {
      type: Object as PropType<BuildCost | Requirement | Reward>,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();

    return { t, abbreviateNumber, formatGroupedNumber };
  },

  computed: {
    cost(): BuildCost {
      return this.item as BuildCost;
    },
    requirement(): Requirement {
      return this.item as Requirement;
    },
    reward(): Reward {
      return this.item as Reward;
    },
    isRequirement(): boolean {
      return (
        "requirement_type" in this.item &&
        this.item.requirement_type !== undefined
      );
    },
    isReward(): boolean {
      return "type" in this.item && this.item.type !== undefined;
    },
    isCost(): boolean {
      return !this.isRequirement && !this.isReward;
    },
    canLink(): boolean {
      return false;
    },
    tag(): String {
      if (this.canLink) {
        return "a";
      } else {
        return "span";
      }
    },
    itemLink(): String | null | undefined {
      if (this.isRequirement) {
        const r = this.item as unknown as Requirement;
        if (r.requirement_type == "BuildingLevel") {
          return `/buildings/${r.requirement_id}?level=${r.requirement_level}`;
        } else if (r.requirement_type == "ResearchLevel") {
          return `/researches/${r.requirement_id}?level=${r.requirement_level}`;
        }
      } else if (this.isCost) {
        return null;
      } else if (this.isReward) {
        return null;
      }
      console.error("Unhandled item in ItemCard");
    },
    resourceId(): number {
      return (this.item as any).resource_id as number;
    },
    resourceBackgroundClass() {
      if (this.isCost) {
        const dataStore = useYukiDataStore();

        const r = this.item as unknown as BuildCost;
        const r_id = r.resource_id;
        const rarity = dataStore.resources.get(r_id)?.rarity;
        switch (rarity) {
          case Rarity.Common: {
            return "";
          }
          case Rarity.Uncommon: {
            return "text-green-600 dark:text-green-400";
          }
          case Rarity.Rare: {
            return "text-blue-600 dark:text-blue-400";
          }
          case Rarity.Epic: {
            return "text-red-600 dark:text-red-400";
          }
        }
      }
      return "";
    },
  },
});
</script>
