<template>
  <span
    class="relative flex justify-center items-center first:rounded-l last:rounded-r h-8 ring-1 ring-inset -ml-px slast:-ml-0"
    @click.stop.prevent="updateChecked"
    :class="
      selected
        ? 'z-20 shadow-inner ring-indigo-500'
        : ' ring-gray-300 dark:ring-gray-500 z-10 '
    "
  >
    <input type="radio" class="appearance-none sr-only" :id="id" :name="id" />
    <label :class="selected ? 'text-green-500' : ' '" class="select-none px-2" :for="id">
      <slot>{{ label }}</slot>
    </label>
  </span>
</template>

<script lang="ts">
import { ref, reactive, defineComponent } from "vue";

export default defineComponent({
  name: "TextToggle",
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    pick: {
      type: [Number, String],
      required: true,
    },
    value: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      ePick: this.pick,
    };
  },
  emits: ["update:pick"],
  methods: {
    updateChecked(e: any) {
      this.$emit("update:pick", this.value);
    },
  },
  watch: {
    pick() {
      this.ePick = this.pick;
    },
  },
  computed: {
    selected(): boolean {
      return this.pick == this.value;
    },
  },
});
</script>
