<template>
  <generic-card v-if="!hide" class="md:min-w-ship-component-card">
    <div class="flex items-center h-11">
      <img :src="componentImage" width="64" class="select-none" />
      <span class="font-bold tabular-nums">{{ componentName }}</span>
    </div>
    <stat-grid
      v-if="showStats"
      class="text-sm dark:border-opacity-25 dark:border-gray-50 border-t border-opacity-25 border-gray-900 max-w-ship-component pt-2 -mt-2"
    >
      <template v-if="component.data.tag === 'Armor'">
        <stat-line name="Armor" :value="component.data.plating" />
        <stat-line name="HP" :value="component.data.hp" />
      </template>
      <template v-if="component.data.tag === 'Cargo'">
        <stat-line name="Resources" :value="component.data.max_resources" />
        <stat-line name="Protected" :value="component.data.protected" />
      </template>
      <template v-if="component.data.tag === 'Impulse'">
        <stat-line name="Dodge" :value="component.data.dodge" />
        <stat-line name="Impulse" :value="component.data.impulse" />
      </template>
      <template v-if="component.data.tag === 'Shield'">
        <stat-line name="Absorption" :value="component.data.absorption" />
        <stat-line name="Mitigation" percent :value="component.data.mitigation" />
        <stat-line name="HP" :value="component.data.hp" />
        <stat-line name="Regen Time" :value="component.data.regen_time" />
      </template>
      <template v-if="component.data.tag === 'Warp'">
        <stat-line name="Range" :value="component.data.distance" />
        <stat-line name="Speed" :value="component.data.speed" />
        <template v-for="ability in component.data.abilities">
          <template v-if="ability.InstantWarp">
            <stat-line name="Jump Cost" :value="ability.InstantWarp.base_cost" />
            <stat-line
              name="Summon Cost"
              :value="
                ability.InstantWarp.base_cost * ability.InstantWarp.cost_multiplier
              "
            />
          </template>
          <template v-else-if="ability.Support">
            <stat-line name="Support Duration" :value="ability.Support.duration" />
            <stat-line name="Support Cooldown" :value="ability.Support.cooldown" />
            <stat-line name="Support Cost" :value="ability.Support.base_cost" />
          </template>
        </template>
      </template>
      <template v-if="component.data.tag === 'Weapon'">
        <stat-line
          name="Type"
          :value="
            component.data.weapon_type == 1
              ? t('weapon.energy')
              : t('weapon.kinetic')
          "
        />
        <stat-line name="Shot" :value="component.data.shots" />
        <stat-line name="Warmup" :value="component.data.warm_up" />
        <stat-line name="Cooldown" :value="component.data.cool_down" />
        <stat-line name="Accuracy" :value="component.data.accuracy" />
        <stat-line name="Penetration" :value="component.data.penetration" />
        <stat-line name="Modulation" :value="component.data.modulation" />
        <stat-line name="Min Dmg" :value="component.data.minimum_damage" />
        <stat-line name="Max Dmg" :value="component.data.maximum_damage" />
        <stat-line name="Crit Chance" percent :value="component.data.crit_chance" />
        <stat-line name="Crit Dmg" percent :value="component.data.crit_modifier" />
      </template>
      <template v-if="component.data.tag === 'Special'">
        <stat-line name="Mining Speed" :value="component.data.mining_speed" percent />
      </template>
    </stat-grid>
    <div
      id="ship-component-costs"
      v-if="buildCost.length > 0"
      class="self-stretch mt-auto dark:border-opacity-25 dark:border-gray-50 border-t border-opacity-25"
      :class="{
        '-mt-2': !showStats,
      }"
    >
      <item-line v-for="c in buildCost" :key="c.resource_id" :item="c" />
    </div>
  </generic-card>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue";

import { BuildCost, ShipDetailComponent } from "~/lib/yuki/models";

import ArmorImage from "~/assets/ship-components/Armor.png";
import CargoImage from "~/assets/ship-components/Cargo.png";
import ImpulseImage from "~/assets/ship-components/Impulse.png";
import ShieldImage from "~/assets/ship-components/Shield.png";
import SpecialImage from "~/assets/ship-components/Special.png";
import WarpSporeImage from "~/assets/ship-components/Warp_Spore.png";
import WarpImage from "~/assets/ship-components/Warp.png";
import WeaponEnergyImage from "~/assets/ship-components/Weapon_Energy.png";
import WeaponKineticImage from "~/assets/ship-components/Weapon_Kinetic.png";

import ItemLine from "./generic/ItemLine.vue";
import GenericCard from "./ui/GenericCard.vue";
import StatLine from "./generic/StatLine.vue";
import { yukiFormat } from "./ui/YukiText.vue";
import { useI18n } from "vue-i18n";
import StatGrid from "./generic/StatGrid.vue";
import { getStaticTranslation, sortCosts } from "~/lib/yuki/utils";

export default defineComponent({
  name: "ComponentCard",
  components: {
    ItemLine,
    GenericCard,
    StatLine,
    StatGrid,
  },
  props: {
    component: {
      type: Object as PropType<ShipDetailComponent>,
      required: true,
    },
    additionalCosts: {
      type: Object as PropType<Array<ShipDetailComponent>>,
    },
    showStats: {
      type: Boolean,
    },
    tier: {
      type: [Number, String],
    },
    tierNameOverride: {
      type: [String, Number],
    },
  },
  setup(props) {
    const componentImage = computed(() => {
      switch (props.component.art_id) {
        case 2001: {
          return ArmorImage;
        }
        case 6001: {
          return CargoImage;
        }
        case 4001: {
          return ImpulseImage;
        }
        case 3001: {
          return ShieldImage;
        }
        case 7001: {
          return SpecialImage;
        }
        case 5001: {
          return WarpImage;
        }
        case 5002: {
          return WarpSporeImage;
        }
        case 1501: {
          return WeaponKineticImage;
        }
        case 1001: {
          return WeaponEnergyImage;
        }
      }
    });

    const hide = computed(() => {
      return (
        props.component.data.tag == "Sensor" ||
        props.component.data.tag == "Deflector" ||
        (props.component.data.tag == "Special" &&
          props.component.data.mining_speed == 0)
      );
    });

    const produceBuildCost = () => {
      const costs: { [key: string]: number } = {};

      for (const cost of props.component.build_cost) {
        costs[cost.resource_id] = (costs[cost.resource_id] || 0) + cost.amount;
      }

      if (props.additionalCosts) {
        for (const aComponent of props.additionalCosts) {
          for (const cost of aComponent.build_cost) {
            costs[cost.resource_id] =
              (costs[cost.resource_id] || 0) + cost.amount;
          }
        }
      }
      return sortCosts(
        Object.entries(costs).map(([key, value]) => {
          return {
            resource_id: parseInt(key),
            amount: value,
          } as BuildCost;
        })
      );
    };

    const buildCost = computed(() => {
      return produceBuildCost();
    });

    const componentName = computed(() => {
      const componentTier = props.tierNameOverride
        ? "{0} " + props.tierNameOverride
        : getStaticTranslation(
          "ship_components",
          -1,
          `component_tier_${props.tier}`,
          false
        );
      return yukiFormat(
        componentTier || "{0}",
        (getStaticTranslation(
          "ship_components",
          props.component.loca_id,
          "name",
          false
        ) || props.component.data.tag)
      );
    });

    const { t } = useI18n();

    return { componentImage, componentName, hide, buildCost, t };
  },
});
</script>

