<template>
    <path v-if="d" fill="transparent" stroke="#485a74" strokeWidth="1pt" :d="d" />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { curveBundle, line } from 'd3-shape';
import { getBezierPath } from './Edge.utils';

const props = defineProps<{
    edge: any
}>();

const d = computed(() => {
    const sections = props.edge.sections;
    if (!sections?.length) {
        return null;
    }

    // Handle bend points that elk gives
    // us separately from drag points
    if (sections[0].bendPoints) {
        const points: any[] = sections
            ? [
                sections[0].startPoint,
                ...(sections[0].bendPoints || ([] as any)),
                sections[0].endPoint
            ]
            : [];

        const pathFn = line()
            .x((d: any) => d.x)
            .y((d: any) => d.y)
            .curve(curveBundle.beta(1));

        return pathFn(points);
    } else {
        return getBezierPath({
            sourceX: sections[0].startPoint.x,
            sourceY: sections[0].startPoint.y,
            targetX: sections[0].endPoint.x,
            targetY: sections[0].endPoint.y
        });
    }
});
</script>
zs