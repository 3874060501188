  
  <template>
  <label class="flex items-center cursor-pointer">
    <div class="relative">
      <input type="checkbox" class="sr-only" :checked="value" @change="updateInput" />
      <div class="block dark:bg-gray-600 bg-gray-200 w-14 h-7 rounded-full"></div>
      <div
        class="dot absolute left-1 top-0.5 dark:bg-white bg-gray-600 w-6 h-6 rounded-full transition"
      ></div>
    </div>
    <div class="ml-3 font-medium whitespace-nowrap">
      <slot />
    </div>
  </label>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Toggle",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    updateInput(e: any) {
      this.$emit("update:value", e.target.checked);
    },
  },
});
</script>
<style scoped>
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #60a5fa;
}
</style>