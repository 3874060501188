<template>
  <div class="flex flex-wrap justify-stretch gap-1">
    <number-input
      class="flex-grow"
      inline
      :value="valueMin"
      @update:value="emit('update:valueMin', $event)"
      :min="min"
      :max="max"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-4 w-4"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M19 10l-7 7m0 0l-7-7m7 7V3"
        />
        <line
          x1="4"
          y1="21"
          x2="20"
          y2="21"
          stroke-width="2"
          stroke-linecap="round"
        />
      </svg>
    </number-input>
    <!-- <p>{{ t("range.to") }}</p> -->
    <number-input
      class="flex-grow"
      :value="valueMax"
      @update:value="emit('update:valueMax', $event)"
      :min="min"
      :max="max"
      inline
      ><svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-4 w-4"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <line
          x1="4"
          y1="3"
          x2="20"
          y2="3"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M5 14l7-7m0 0l7 7m-7-7v14"
        /></svg
    ></number-input>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

import NumberInput from "../ui/NumberInput.vue";

export default defineComponent({
  name: "NumberRangeFilter",
  components: {
    NumberInput,
  },
  props: {
    name: {
      type: String,
    },
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    valueMin: {
      type: Number,
      required: true,
    },
    valueMax: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();

    return { t, emit };
  },
});
</script>
