<template>
  <img :src="thumbImage" />
</template>

<script lang="ts">
import { computed, defineComponent, unref } from "vue";
import { researchThumb } from "~/lib/thumbs";
import { useYukiDataStore } from "~/stores";

export default defineComponent({
  name: "ResearchThumb",
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    return {
      thumbImage: computed(() => {
        const dataStore = useYukiDataStore();
        const research = dataStore.researches.find((x) => x.id === props.id);
        return unref(researchThumb(research?.art_id ?? props.id));
      }),
    };
  },
});
</script>
