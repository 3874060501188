<template>
  <template v-if="isCost">
    <span class="inline-flex items-center ml-auto font-bold tabular-nums">{{ abbreviateNumber(cost.amount) }}</span>
    <resource-thumb class="mx-1 my-auto" width="24" height="24" :id="cost.resource_id" />
    <yuki-text class="flex items-center text-md px-1" skey="name" :id="cost.resource_id" category="materials" />
  </template>
  <template v-if="isRequirement">
    <template v-if="requirement.requirement_type == 'BuildingLevel'">
      <building-thumb class="my-auto" width="32" height="32" :id="requirement.requirement_id" />
      <yuki-text class="text-sm pl-1" skey="name" :id="requirement.requirement_id" category="buildings" />
    </template>
    <template v-if="requirement.requirement_type == 'ResearchLevel'">
      <research-thumb class="my-auto" width="32" height="32" :id="requirement.requirement_id" />
      <yuki-text class="text-sm px-1 text-center" skey="name" :id="requirement.requirement_id" category="research" />
    </template>
    <div class="flex justify-between px-1 text-sm">
      <span>{{ t("building.level") }}</span>
      <span class="ml-2">{{ requirement.requirement_level }}</span>
    </div>
  </template>
  <template v-if="isReward">
    <span class="inline-flex items-center ml-auto font-bold tabular-nums">{{ abbreviateNumber(cost.amount) }}</span>
    <template v-if="reward.type == 1">
      <span class="mx-1 flex items-center whitespace-nowrap">
        &#215;
        <resource-thumb class="ml-1 my-auto h-6" :id="resourceId" />
      </span>
      <yuki-text class="text-md text-left" skey="blueprint_name" :id="resourceId" category="blueprints"
        :class="resourceBackgroundClass" />
    </template>
    <template v-else-if="reward.type == 3">
      <span class="mx-1 flex items-center whitespace-nowrap">
        &#215;
        <resource-thumb class="ml-1 my-auto h-6" :id="resourceId" />
      </span>
      <yuki-text class="text-md text-left" skey="name" :id="resourceId" category="materials"
        :class="resourceBackgroundClass" />
    </template>
    <template v-else-if="reward.type == 5">
      <span class="mx-1 flex items-center whitespace-nowrap">&#215;</span>
      <yuki-text class="text-md text-left" skey="name" :id="resourceId" category="mission"
        :class="resourceBackgroundClass" />
    </template>
    <template v-else-if="reward.type == 6">
      <span class="mx-1 flex items-center whitespace-nowrap">
        &#215;
        <img :src="missionUnlockPath" class="ml-1 my-auto h-6" />
      </span>
      <span class="flex gap-x-1" v-if="connectionsForId">
        <router-link class="text-indigo-700 dark:text-indigo-400" :to="'/systems/' + connectionsForId.from">
          <YukiText category="systems" skey="name" :id="Number(connectionsForId.from)" />
        </router-link>
        {{ t('range.to') }}
        <router-link class="text-indigo-700 dark:text-indigo-400" :to="'/systems/' + connectionsForId.to">
          <YukiText category="systems" skey="name" :id="Number(connectionsForId.to)" />
        </router-link>
      </span>
    </template>
    <template v-else-if="reward.type == 8">
      <span class="mx-1 flex items-center whitespace-nowrap">
        &#215;
        <consumable-thumb class="ml-1 my-auto h-6" :id="consumableArtId" />
      </span>
      <yuki-text class="text-md text-left" skey="name" :id="reward.resource_id" category="consumables"
        :class="resourceBackgroundClass" />
    </template>
    <template v-else-if="reward.type == 11">
      <span class="mx-1 flex items-center whitespace-nowrap">
        &#215;
        <officer-thumb class="ml-1 my-auto h-6" :id="officerArtId" />
      </span>
      <yuki-text class="text-md text-left" skey="name" :id="reward.resource_id" category="officers"
        :class="resourceBackgroundClass" />
    </template>
    <template v-else>
      <span class="mx-1 flex items-center whitespace-nowrap">
        &#215;
        <resource-thumb class="ml-1 my-auto h-6" :id="resourceId" />
      </span>
      <yuki-text class="text-md text-left" skey="name" :id="resourceId" category="materials"
        :class="resourceBackgroundClass" />
    </template>
  </template>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import { Reward, Requirement, BuildCost, Rarity } from "~/lib/yuki/models";

import BuildingThumb from "../thumbs/BuildingThumb.vue";
import ResearchThumb from "../thumbs/ResearchThumb.vue";
import ResourceThumb from "../thumbs/ResourceThumb.vue";
import OfficerThumb from "../thumbs/OfficerThumb.vue";
import ConsumableThumb from "../thumbs/ConsumableThumb.vue";

import MaybeLink from "../ui/MaybeLink.vue";
import YukiText from "../ui/YukiText.vue";

import missionUnlockPath from "~/assets/resources/unlockPath_masked.png";

import { abbreviateNumber, formatGroupedNumber } from "~/lib/formatters";

import { useI18n } from "vue-i18n";
import { useYukiDataStore } from "~/stores";
import ShipThumb from "../thumbs/ShipThumb.vue";

export default defineComponent({
  name: "ItemLineGrid2",
  components: {
    "yuki-text": YukiText,
    "maybe-link": MaybeLink,
    "building-thumb": BuildingThumb,
    "research-thumb": ResearchThumb,
    "resource-thumb": ResourceThumb,
    "officer-thumb": OfficerThumb,
    "consumable-thumb": ConsumableThumb,
    ShipThumb
  },
  props: {
    left: {
      type: Boolean,
    },
    item: {
      type: Object as PropType<BuildCost | Requirement | Reward>,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();

    const dataStore = useYukiDataStore();


    return { t, abbreviateNumber, formatGroupedNumber, missionUnlockPath };
  },

  computed: {
    cost(): BuildCost {
      return this.item as BuildCost;
    },
    connectionsForId(): any {
      const dataStore = useYukiDataStore();
      const r = dataStore.mission_warp_unlocks.find(x => x.id == this.resourceId);
      return r;
    },
    requirement(): Requirement {
      return this.item as Requirement;
    },
    reward(): Reward {
      return this.item as Reward;
    },
    isRequirement(): boolean {
      return (
        "requirement_type" in this.item &&
        this.item.requirement_type !== undefined
      );
    },
    isReward(): boolean {
      return "type" in this.item && this.item.type !== undefined;
    },
    isCost(): boolean {
      return !this.isRequirement && !this.isReward;
    },
    resourceId(): number {
      return (this.item as any).resource_id as number;
    },
    consumableArtId(): number {
      const dataStore = useYukiDataStore();
      return dataStore.consumables.find((x) => x.id == this.resourceId)?.art_id ?? 0;
    },
    officerArtId(): number {
      const dataStore = useYukiDataStore();
      return dataStore.officers.find((x) => x.id == this.resourceId)?.art_id ?? 0;
    },
    canLink(): boolean {
      return false;
    },
    tag(): String {
      if (this.canLink) {
        return "a";
      } else {
        return "span";
      }
    },
    itemLink(): String | null | undefined {
      if (this.isRequirement) {
        const r = this.item as unknown as Requirement;
        if (r.requirement_type == "BuildingLevel") {
          return `/buildings/${r.requirement_id}?level=${r.requirement_level}`;
        } else if (r.requirement_type == "ResearchLevel") {
          return `/researches/${r.requirement_id}?level=${r.requirement_level}`;
        }
      } else if (this.isCost) {
        return null;
      } else if (this.isReward) {
        return null;
      }
      console.error("Unhandled item in ItemCard");
    },
    resourceBackgroundClass() {
      if (this.isCost || this.isReward) {
        const dataStore = useYukiDataStore();

        const r = this.item as unknown as BuildCost;
        const r_id = r.resource_id;
        const rarity = dataStore.resources.get(r_id)?.rarity;
        switch (rarity) {
          case Rarity.Common: {
            return "";
          }
          case Rarity.Uncommon: {
            return "text-green-600 dark:text-green-400";
          }
          case Rarity.Rare: {
            return "text-blue-600 dark:text-blue-400";
          }
          case Rarity.Epic: {
            return "text-red-600 dark:text-red-400";
          }
        }
      }
      return "";
    },
  },
});
</script>
