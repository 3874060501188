<template>
  <div class="item-detail-wrapper">
    <slot name="header" />
    <div class="item-detail-content">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "DetailPageWrapper",
});
</script>
