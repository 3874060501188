<template>
  <div class="overflow-x-auto min-h-fit">
    <slot name="title">
      <div v-if="title" class="font-bold mb-2">{{ title }}</div>
    </slot>
    <div class="overflow-x-auto">
      <table class="w-full divide-x divide-gray-200 dark:divide-gray-500 flex">
        <tbody class="bg-white dark:bg-gray-600 divide-x divide-gray-200 dark:divide-gray-500 flex">
          <tr
            class="sticky left-0 flex flex-col justify-center divide-y divide-gray-200 dark:divide-gray-500"
          >
            <th
              v-for="header in headers"
              :key="header.text"
              scope="col"
              class="bg-table-header px-2 py-3 text-right text-xs text-gray-500 dark:text-gray-100 uppercase tracking-wider font-bold"
            >{{ header.text }}</th>
          </tr>
          <div class="flex flex-row">
            <tr v-for="(item, index) in items" :key="index" class="w-full">
              <div
                class="flex flex-col justify-center h-full divide-y divide-gray-200 dark:divide-gray-500"
              >
                <td
                  v-for="(header, index) in headers"
                  :key="index"
                  class="text-center px-2 py-2 whitespace-nowrap text-sm dark:text-gray-100 tabular-nums"
                >{{ f(header, item[header.value]) }}</td>
              </div>
            </tr>
          </div>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { formatGroupedNumber, formatPercentageNumber } from "~/lib/formatters";
import { TableHeader } from "./YTable.vue";

export default defineComponent({
  name: "YTable",
  props: {
    headers: {
      type: Array as PropType<Array<TableHeader>>,
      required: true,
    },
    items: {
      type: Array as PropType<Array<any>>,
      required: true,
    },
    title: {
      type: String,
    },
  },
  setup() {
    const f = (h: any, v: any) => {
      if (typeof v == "number") {
        if (h.percentage) {
          return formatPercentageNumber(v);
        } else {
          return formatGroupedNumber(v);
        }
      } else {
        return v;
      }
    };
    return { f };
  },
});
</script>
