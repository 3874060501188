import { mande } from 'mande'

let api_url = import.meta.env.VITE_API_URL as string;
if (!api_url.endsWith("/")) {
    api_url += "/";
}
api_url += "v1";


let lastKnownVersion;
try {
    lastKnownVersion = window.localStorage.getItem('dataVersion');
}
catch (e) {
    lastKnownVersion = undefined;
}

const YukiApi = mande(api_url, {
    query: { "n": "web-client", version: lastKnownVersion },
    headers: import.meta.env.VITE_API_CREDS ? {
        'Authorization': ('Basic ' + btoa(import.meta.env.VITE_API_CREDS as string))
    } : {}
});

export { YukiApi };