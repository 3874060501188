<template>
  <list-item wide>
    <template v-slot:image>
      <research-thumb width="64" height="64" :id="item.id" />
    </template>
    <template v-slot:name>
      <div class="inline-flex items-center truncate">
        <yuki-text
          class="truncate font-bold"
          skey="name"
          :id="item.id"
          category="research"
        />
      </div>
    </template>
    <template v-slot:sub> Unlock Level: {{ item.unlock_level }} </template>
    <template v-slot:extra>
      <div class="grid grid-cols-6ch-2 grid-rows-3 text-sm gap-x-2">
        <span>Tree:</span> <span class="font-bold whitespace-nowrap"><yuki-text uf skey="tree_name" category="research" :id="item.research_tree"/></span>
        <span>Row:</span> <span class="font-bold">{{ item.row }}</span>
        <span>Column:</span> <span class="font-bold">{{ item.column }}</span>
      </div>
    </template>
  </list-item>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import ListItem from "../ui/ListItem.vue";

import housingIcon from "~/assets/icons/misc/housing.png";
import missionIcon from "~/assets/icons/misc/mission.png";
import deepSpaceIcon from "~/assets/icons/misc/deepspace.png";

import armadaIcon from "~/assets/icons/ship_types/armada.png";
import battleShipIcon from "~/assets/icons/ship_types/battleship.png";
import explorerIcon from "~/assets/icons/ship_types/explorer.png";
import interceptorIcon from "~/assets/icons/ship_types/interceptor.png";
import surveyIcon from "~/assets/icons/ship_types/survey.png";

import ResourceThumb from "../thumbs/ResourceThumb.vue";
import ResearchThumb from "../thumbs/ResearchThumb.vue";
import { Research } from "~/lib/yuki/models";

export default defineComponent({
  components: {
    ListItem,
    ResourceThumb,
    ResearchThumb,
  },
  props: {
    item: {
      type: Object as PropType<Research>,
      required: true,
    },
  },
  setup(props) {
    return {
      housingIcon,
      missionIcon,
      deepSpaceIcon,
      armadaIcon,
      battleShipIcon,
      explorerIcon,
      interceptorIcon,
      surveyIcon,
    };
  },
});
</script>
