
import { onUnmounted } from 'vue'

export function useWindowEvent<TType extends keyof WindowEventMap>(
  type: TType,
  listener: (this: Window, ev: WindowEventMap[TType]) => any,
  options?: boolean | AddEventListenerOptions
) {
  window.addEventListener(type, listener, options)
  const removeListener = () => window.removeEventListener(type, listener, options);
  onUnmounted(removeListener);
  return removeListener
}