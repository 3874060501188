(window as any).global = window;

import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";

import { createI18n } from "vue-i18n";
import { createPinia } from 'pinia';
import * as Sentry from "@sentry/vue";

import en from "~/i18n/en.json";
import de from "~/i18n/de.json";

import YukiText from "~/components/ui/YukiText.vue";
import { VueCookieNext } from "vue-cookie-next";

import "./index.css";

const messages = {
  en: en,
  de: de,
};

const i18n = createI18n({
  legacy: false,
  locale: 'en',
  fallbackLocale: "en",
  messages,
});

const app = createApp(App);

if (import.meta.env.PROD) {
  Sentry.init({
    app,
    release: import.meta.env.PACKAGE_VERSION as string,
    environment: import.meta.env.VITE_ENVIRONMENT as string,
    dsn: "https://bacb27f636bf49258137238ffa032093@o922117.ingest.sentry.io/5869105",
    hooks: ["activate", "create", "destroy", "mount", "update"],
  });
}

import FloatingVue from 'floating-vue'

app.use(FloatingVue)
app.use(router);
app.use(i18n);
app.use(createPinia());
app.use(VueCookieNext);
app.use({
  install: (app) => {
    app.config.globalProperties.$inputValue = (e: Event) => {
      return (<HTMLInputElement>e.target).value;
    };
  }
});


app.component('YukiText', YukiText);

app.mount("#app");
