<template>
  <maybe-link :to="itemLink" class="item-card" :class="resourceBackgroundClass">
    <template v-if="isCost">
      <yuki-text
        class="text-sm px-1 text-center mb-auto"
        skey="name"
        :id="resourceId"
        category="materials"
      />
      <resource-thumb class="my-auto h-16" :id="resourceId" />
      <div class="flex justify-between px-1 font-bold">
        <span>
          {{
            formatGroupedNumber(amount)
          }}
        </span>
      </div>
    </template>
    <template v-if="isResourceRewardRange">
      <yuki-text
        class="text-sm px-1 text-center mb-auto"
        skey="name"
        :id="resourceRewardRange.resource_id"
        category="materials"
      />
      <resource-thumb class="my-auto h-16" :id="resourceRewardRange.resource_id" />
      <div class="flex justify-between px-1">
        <span class="whitespace-nowrap font-bold">
          {{
            resourceRewardRange.min == resourceRewardRange.max
              ? formatGroupedNumber(resourceRewardRange.min)
              : formatGroupedNumber(resourceRewardRange.min) +
              " - " +
              formatGroupedNumber(resourceRewardRange.max)
          }}
        </span>
      </div>
    </template>
    <template v-if="isRequirement">
      <template v-if="requirementType == 'BuildingLevel'">
        <yuki-text
          class="text-sm px-1 text-center min-h-12"
          skey="name"
          :id="requirementId"
          category="buildings"
        />
        <building-thumb class="my-auto h-16" :id="requirementId" />
      </template>
      <template v-if="requirementType == 'ResearchLevel'">
        <yuki-text
          class="text-sm px-1 text-center min-h-12"
          skey="name"
          :id="requirementId"
          category="research"
        />
        <research-thumb class="my-auto h-16" :id="requirementId" />
      </template>
      <template v-if="requirementType == 'FactionRank'">
        <yuki-text
          class="text-sm px-1 text-center min-h-12"
          skey="name"
          :id="requirementId"
          category="materials"
        />
        <resource-thumb class="my-auto h-16" :id="requirementId" />
        <div class="flex justify-between px-1">
          <span class="ml-2 font-bold">
            {{
              formatGroupedNumber(requirementLevel)
            }}
          </span>
        </div>
      </template>
      <div v-else class="flex justify-between px-1">
        <span>{{ t("ui.level") }}</span>
        <span class="ml-2">{{ requirementLevel }}</span>
      </div>
    </template>
    <template v-if="isReward">
      <template v-if="type == 3">
        <yuki-text
          class="text-sm px-1 text-center min-h-12"
          skey="name"
          :id="resourceId"
          category="materials"
        />
        <resource-thumb class="my-auto h-16" :id="resourceId" />
        <div class="flex justify-between px-1">
          <span class="font-bold">
            {{
              formatGroupedNumber(amount)
            }}
          </span>
        </div>
      </template>
      <template v-if="type == 8">
        <yuki-text
          class="text-sm px-1 text-center"
          skey="name"
          :id="resourceId"
          category="consumables"
        />
        <consumable-thumb class="my-auto h-16" :id="consumableArtId" />
        <yuki-text
          class="text-sm px-1 text-center min-h-12"
          skey="description"
          :id="resourceId"
          category="consumables"
        />
      </template>
      <template v-if="type == 11">
        <yuki-text
          class="text-sm px-1 text-center min-h-12"
          skey="name"
          :id="resourceId"
          category="officers"
        />
        <officer-thumb class="my-auto h-16" :id="officerArtId" />
        <div class="flex justify-between px-1">
          <span class="font-bold">
            {{
              formatGroupedNumber(amount)
            }}
          </span>
        </div>
      </template>
    </template>
  </maybe-link>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import {
  Reward,
  Requirement,
  BuildCost,
  Rarity,
  RequirementType,
  ResourceRewardRange,
} from "~/lib/yuki/models";

import { useYukiDataStore } from "~/stores";

import BuildingThumb from "../thumbs/BuildingThumb.vue";
import ResearchThumb from "../thumbs/ResearchThumb.vue";
import ResourceThumb from "../thumbs/ResourceThumb.vue";
import ConsumableThumb from "../thumbs/ConsumableThumb.vue";
import OfficerThumb from "../thumbs/OfficerThumb.vue";
import MaybeLink from "../ui/MaybeLink.vue";
import YukiText from "../ui/YukiText.vue";

import { useI18n } from "vue-i18n";
import { formatGroupedNumber } from "~/lib/formatters";

export default defineComponent({
  name: "ItemCard",
  components: {
    YukiText,
    MaybeLink,
    BuildingThumb,
    ResearchThumb,
    ResourceThumb,
    ConsumableThumb,
    OfficerThumb
  },
  props: {
    item: {
      type: Object as PropType<
        BuildCost | Requirement | Reward | ResourceRewardRange
      >,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();

    return { t, formatGroupedNumber };
  },
  computed: {
    isRequirement(): boolean {
      return this.item.hasOwnProperty("requirement_type");
    },
    isReward(): boolean {
      return this.item.hasOwnProperty("type");
    },
    isResourceRewardRange(): boolean {
      return this.item.hasOwnProperty("min") && this.item.hasOwnProperty("max");
    },
    isCost(): boolean {
      return (
        !this.isRequirement && !this.isReward && !this.isResourceRewardRange
      );
    },
    canLink(): boolean {
      return false;
    },
    type(): number {
      return (this.item as any).type as number;
    },
    resourceId(): number {
      return (this.item as any).resource_id as number;
    },
    requirementLevel(): number {
      return (this.item as Requirement).requirement_level;
    },
    requirementType(): RequirementType {
      return (this.item as Requirement).requirement_type;
    },
    requirementId(): number {
      return (this.item as Requirement).requirement_id;
    },
    amount(): number {
      return (this.item as Reward).amount;
    },
    resourceRewardRange(): ResourceRewardRange {
      return this.item as ResourceRewardRange;
    },
    consumableArtId(): number {
      const dataStore = useYukiDataStore();
      return dataStore.consumables.find((x) => x.id == this.resourceId)?.art_id ?? 0;
    },
    officerArtId(): number {
      const dataStore = useYukiDataStore();
      return dataStore.officers.find((x) => x.id == this.resourceId)?.art_id ?? 0;
    },
    tag(): String {
      if (this.canLink) {
        return "a";
      } else {
        return "span";
      }
    },
    itemLink(): string | undefined {
      if (this.isRequirement) {
        const r = this.item as unknown as Requirement;
        if (r.requirement_type == "BuildingLevel") {
          return `/buildings/${r.requirement_id}?level=${r.requirement_level}`;
        } else if (r.requirement_type == "ResearchLevel") {
          return `/researches/${r.requirement_id}?level=${r.requirement_level}`;
        } else {
          return undefined;
        }
      } else if (this.isCost) {
        return undefined;
      } else if (this.isReward) {
        return undefined;
      } else if (this.isResourceRewardRange) {
        return undefined;
      }
      console.error("Unhandled item in ItemCard");
    },
    resourceBackgroundClass(): string {
      if (this.isCost) {
        const r = this.item as unknown as BuildCost;
        const r_id = r.resource_id;
        const dataStore = useYukiDataStore();
        const rarity = dataStore.resources.get(r_id)?.rarity;
        switch (rarity) {
          case Rarity.Common: {
            return "";
          }
          case Rarity.Uncommon: {
            return "text-green-600 dark:text-green-400";
          }
          case Rarity.Rare: {
            return "text-blue-600 dark:text-blue-400";
          }
          case Rarity.Epic: {
            return "text-red-600 dark:text-red-400";
          }
        }
      }
      return "";
    },
  },
});
</script>
