
<script setup lang="ts">
import { SpeakerphoneIcon, XIcon } from "@heroicons/vue/outline";

import { useRegisterSW } from "virtual:pwa-register/vue";
import { watch } from "vue";
const { offlineReady, needRefresh, updateServiceWorker } = useRegisterSW();
const refresh = async () => {
  await updateServiceWorker();
  window.location.reload();
};

const close = async () => {
  offlineReady.value = false;
  needRefresh.value = false;
};

watch(needRefresh, async () => {
  if (needRefresh.value) {
    await updateServiceWorker();
  }
});
</script>

<template>
  <div v-if="needRefresh" class="fixed inset-x-0 bottom-0 z-50">
    <div class="bg-indigo-600">
      <div class="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div class="flex items-center justify-between flex-wrap">
          <div class="w-0 flex-1 flex items-center">
            <span class="flex p-2 rounded-lg bg-indigo-800">
              <SpeakerphoneIcon class="h-6 w-6 text-white" aria-hidden="true" />
            </span>
            <p class="ml-3 font-medium text-white truncate">
              <span class="md:hidden truncate">A new update is ready.</span>
              <span class="hidden md:inline">Big news! A new update is ready.</span>
            </p>
          </div>
          <div
            class="order-3 mt-2 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto flex justify-between gap-2"
          >
            <!-- <div class>
              <a
                href="#"
                class="
                  flex
                  items-center
                  justify-center
                  px-4
                  py-2
                  border border-transparent
                  rounded-md
                  shadow-sm
                  text-sm
                  font-medium
                  text-indigo-600
                  bg-white
                  hover:bg-indigo-50
                "
              >
                Learn more
              </a>
            </div>-->
            <div class="ml-auto">
              <button
                @click="refresh"
                v-if="needRefresh"
                type="button"
                class="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-indigo-600 bg-white hover:bg-indigo-50"
              >Update</button>
            </div>
          </div>
          <div class="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
            <button
              @click="close"
              type="button"
              class="-mr-1 flex p-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
            >
              <span class="sr-only">Dismiss</span>
              <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
