
<template>
  <div class="flex flex-wrap gap-4 tabular-nums justify-between">
    <div class="w-full lg:w-1/2 space-y-2">
      <div v-if="time">
        <span class="text-xl font-bold">{{ t("build_time") }}</span>
        <time-display :time="time" />
      </div>
      <div>
        <span class="text-xl font-bold">{{ t("build_cost") }}</span>
        <div class="flex flex-wrap flex-col min-w-fit">
          <item-line class="gap-2" v-for="c in sortedCosts" :key="c.resource_id" :item="c" />
        </div>
      </div>
    </div>
    <div class="space-y-2 flex-1 flex flex-col items-center" v-if="requirements">
      <span class="text-xl font-bold">{{ t("build_requirements") }}</span>
      <div class="flex flex-wrap gap-4">
        <item-card v-for="c in requirements" :key="c.requirement_id" :item="c" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";

// TODO(alexander): Odd location...
import TimeDisplay from "../ui/TimeDisplay.vue";
import ItemLine from "../generic/ItemLine.vue";
import ItemCard from "../generic/ItemCard.vue";
import { BuildCost, Requirement } from "~/lib/yuki/models";
import { sortCosts } from "~/lib/yuki/utils";

const props = defineProps<{
  costs: Array<BuildCost>,
  time?: number,
  requirements?: Array<Requirement>,
}>();

const { t } = useI18n();

const sortedCosts = ref(sortCosts(props.costs));

watch(props, () => {
  sortedCosts.value = sortCosts(props.costs);
});

</script>
