<template>
  <list-item>
    <template v-slot:image>
      <ship-thumb width="64" height="64" :id="item.id" />
    </template>
    <template v-slot:name>
      <div class="inline-flex items-center truncate">
        <span class="hidden xs3:block">
          <rarity-label :rarity="item.rarity"></rarity-label>
        </span>
        <yuki-text class="truncate font-bold" skey="name" :id="item.id" category="ships" />
      </div>
    </template>
    <template v-slot:sub>
      <span class="flex gap-2 items-center">
        <yuki-text class="text-sm" skey="name" :id="item.hull_type" category="ship_type" />
      </span>
    </template>
    <template v-slot:extra>
      <div class="grid grid-cols-9ch-2 grid-rows-3 text-sm gap-x-2">
        Grade: <span class="font-bold">{{ item.grade }}</span>
        Shipyard:
        <span class="font-bold">{{ requiredShipyardLevel(item) }}</span>
        <yuki-text class="text-sm" skey="name" fallback="Neutral" :id="item.faction" category="factions" />
      </div>
    </template>
  </list-item>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import ListItem from "../ui/ListItem.vue";

import housingIcon from "~/assets/icons/misc/housing.png";
import missionIcon from "~/assets/icons/misc/mission.png";
import deepSpaceIcon from "~/assets/icons/misc/deepspace.png";

import armadaIcon from "~/assets/icons/ship_types/armada.png";
import battleShipIcon from "~/assets/icons/ship_types/battleship.png";
import explorerIcon from "~/assets/icons/ship_types/explorer.png";
import interceptorIcon from "~/assets/icons/ship_types/interceptor.png";
import surveyIcon from "~/assets/icons/ship_types/survey.png";

import ResourceThumb from "../thumbs/ResourceThumb.vue";
import FactionThumb from "../thumbs/FactionThumb.vue";
import ShipThumb from "../thumbs/ShipThumb.vue";
import RarityLabel from "../generic/RarityLabel.vue";
import { Requirement, Ship } from "~/lib/yuki/models";

export default defineComponent({
  components: {
    ListItem,
    ResourceThumb,
    FactionThumb,
    ShipThumb,
    RarityLabel,
  },
  props: {
    item: {
      type: Object as PropType<Ship>,
      required: true,
    },
  },
  setup(props) {
    const requiredShipyardLevel = (ship: Ship) => {
      return (
        ship.build_requirements.find(
          (x: Requirement) => x.requirement_type == "BuildingLevel" && x.requirement_id == 15
        )?.requirement_level || 0
      );
    };

    return {
      requiredShipyardLevel,

      housingIcon,
      missionIcon,
      deepSpaceIcon,
      armadaIcon,
      battleShipIcon,
      explorerIcon,
      interceptorIcon,
      surveyIcon,
    };
  },
});
</script>
