<template>
  <list-item wide>
    <template v-slot:image>
      <faction-thumb
        class="object-contain"
        width="64"
        height="64"
        :style="{ maxHeight: '64px' }"
        :id="item.faction"
      />
    </template>
    <template v-slot:name>
      <div class="inline-flex items-center truncate">
        <yuki-text class="truncate font-bold" skey="title" :id="item.id" category="missions" />
      </div>
    </template>
    <template
      v-slot:sub
      v-if="item.warp_for_completion != -1"
    >Warp: {{ item.warp }} | Completion: {{ item.warp_for_completion }}</template>
  </list-item>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import ListItem from "../ui/ListItem.vue";

import housingIcon from "~/assets/icons/misc/housing.png";
import missionIcon from "~/assets/icons/misc/mission.png";
import deepSpaceIcon from "~/assets/icons/misc/deepspace.png";

import armadaIcon from "~/assets/icons/ship_types/armada.png";
import battleShipIcon from "~/assets/icons/ship_types/battleship.png";
import explorerIcon from "~/assets/icons/ship_types/explorer.png";
import interceptorIcon from "~/assets/icons/ship_types/interceptor.png";
import surveyIcon from "~/assets/icons/ship_types/survey.png";

import ResourceThumb from "../thumbs/ResourceThumb.vue";
import FactionThumb from "../thumbs/FactionThumb.vue";
import { Mission } from "~/lib/yuki/models";

export default defineComponent({
  components: {
    ListItem,
    ResourceThumb,
    FactionThumb,
  },
  props: {
    item: {
      type: Object as PropType<Mission>,
      required: true,
    },
  },
  setup(props) {
    return {
      housingIcon,
      missionIcon,
      deepSpaceIcon,
      armadaIcon,
      battleShipIcon,
      explorerIcon,
      interceptorIcon,
      surveyIcon,
    };
  },
});
</script>
