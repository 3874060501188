<template>
  <span class="w-full block">Rarity:</span>
  <span class="flex flex-wrap min-h-8 rounded">
    <text-radio
      id="ShipTest"
      label="All"
      :pick="filter"
      value="-1"
      @update:pick="updatePick"
    />
    <text-radio
      id="ShipTest"
      label="Common"
      :pick="filter"
      value="1"
      @update:pick="updatePick"
    />
    <text-radio
      id="ShipTest"
      label="Uncommon"
      :pick="filter"
      value="2"
      @update:pick="updatePick"
    />
    <text-radio
      id="ShipTest"
      label="Rare"
      :pick="filter"
      value="3"
      @update:pick="updatePick"
    />
    <text-radio
      id="ShipTest"
      label="Epic"
      :pick="filter"
      value="4"
      @update:pick="updatePick"
    />
  </span>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TextRadio from "../ui/TextRadio.vue";

export default defineComponent({
  name: "RarityFilterGroup",
  components: {
    TextRadio,
  },
  methods: {
    updatePick(e: any) {
      this.$emit("update:filter", e);
    },
  },
  props: {
    filter: {
      type: [String, Number],
      required: true,
    },
  },
});
</script>
