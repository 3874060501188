import { defineStore } from 'pinia';

import { YukiApi } from "./api";
import { markRaw, toRaw } from 'vue';
import { chunk, uniq } from 'lodash';

interface Translation {
    /** Id for this translation */
    id: number;
    /** The sub-type for this is translation */
    key: string;
    /** The actual text for this translation */
    text: string;
}

let pendingTranslationTimeout: number | undefined = undefined;

const staticTranslations = [
    { path: "materials" },
    { path: "ships" },
    { path: "officers" },
    { path: "officers_synergy" },
    { path: "research" },
    { path: "buildings" },
    { path: "building_buffs" },
    { path: "factions" },
    { path: "officer_division" },
    { path: "ship_type" },
    { path: "systems" },
    { path: "ship_components" },
    { path: "consumables" },
    { path: "hostiles" },
    { path: "missions", query: { 'key': 'title' } },
    { path: "traits" },
];

const formatterTemplates = {
    abbrvNumber: {
        notation: "compact",
        minimumSignificantDigits: 1,
        maximumSignificantDigits: 3,
        compactDisplay: "short",
    },
}

export const useYukiTranslationStore = defineStore({
    id: 'yukiTranslations',
    state: () => {
        const validLangs = ["en", "fr", "it", "de", "es", "ru", "pt", "ja", "ko"];
        const queryLanguage = new URL(location.href).searchParams.get("lang") as string;
        const defaultLanguage = navigator.languages
            ? navigator.languages[0]
            : navigator.language;
        const defaultLanguageCode = defaultLanguage.slice(0, 2);
        let lang = defaultLanguageCode;
        try {
            lang = window.localStorage.getItem("lang") || defaultLanguageCode;
        } catch {
            lang = defaultLanguageCode;
        }
        return {
            data: new Map<string, Map<string, Map<string, string>>>(),
            selectedLanguage: lang && validLangs.includes(lang) ? lang : 'en',
            pendingTranslations: markRaw({} as { [key: string]: (string | number)[] }),
            formatters: {
                abbrvNumber: new Intl.NumberFormat(lang, {
                    notation: "compact",
                    minimumSignificantDigits: 1,
                    maximumSignificantDigits: 3,
                    compactDisplay: "short",
                }),
                decimalNumber: new Intl.NumberFormat(lang, {
                    notation: "standard",
                }),
                percentNumber: new Intl.NumberFormat(lang, {
                    style: "percent",
                    useGrouping: true,
                    maximumFractionDigits: 6,
                }),
                percentNumber2: new Intl.NumberFormat(lang, {
                    style: "percent",
                    useGrouping: true,
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 6,
                }),
                groupedNumber: new Intl.NumberFormat(lang, {
                    useGrouping: true,
                    maximumFractionDigits: 6,
                })
            }
        }
    },
    getters: {
        allData(state) {
            return state.data
        }
    },
    actions: {
        setTranslation(category: string, id: number | string, key: string, text: string) {
            let c = this.data.get(category);
            if (!c) {
                c = new Map();
                this.data.set(category, c);
            }
            let t = c.get(String(id));
            if (!t) {
                t = new Map();
                c.set(String(id), t);
            }
            t.set(key, text);
        },
        changeLanguage(language: string) {
            if (this.selectedLanguage === language) {
                return;
            }

            window.localStorage.setItem("lang", language);
            this.selectedLanguage = language;
            this.formatters.abbrvNumber = new Intl.NumberFormat(language, {
                notation: "compact",
                minimumSignificantDigits: 1,
                maximumSignificantDigits: 3,
                compactDisplay: "short",
            });
            this.formatters.decimalNumber = new Intl.NumberFormat(language, {
                notation: "standard",
            });
            this.formatters.percentNumber = new Intl.NumberFormat(language, {
                style: "percent",
                useGrouping: true,
                maximumFractionDigits: 2,
            });
            this.formatters.percentNumber2 = new Intl.NumberFormat(language, {
                style: "percent",
                useGrouping: true,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
            this.formatters.groupedNumber = new Intl.NumberFormat(language, {
                useGrouping: true,
                maximumFractionDigits: 2,
            });
            this.fetchStaticTranslations();
        },

        async doFetch() {
            for (const pendingTranslationKey in this.pendingTranslations) {
                const pendingTranslation = this.pendingTranslations[pendingTranslationKey];
                for (const c of chunk(pendingTranslation, 50)) {
                    const path = `/translations/${this.selectedLanguage}/${pendingTranslationKey}/${uniq(c).join(',')}`
                    const j = await YukiApi.get<Translation[]>(path);
                    for (const { id, key, text } of j) {
                        this.setTranslation(pendingTranslationKey, id, key, text);
                    }
                }
                delete this.pendingTranslations[pendingTranslationKey];
            }
        },
        async fetchDynamicTranslations(category: string, ids: (string | number)[]) {
            if (!this.pendingTranslations[category]) {
                this.pendingTranslations[category] = markRaw([]);
            }
            this.pendingTranslations[category].push(...ids);

            if (pendingTranslationTimeout) {
                clearTimeout(pendingTranslationTimeout);
            }
            pendingTranslationTimeout = setTimeout(() => {
                this.doFetch();
            }, 10);
        },
        async fetchStaticTranslations(lang: string | undefined = undefined) {
            let allCachable = true;

            const loadTranslation = async (category: { path: string, query?: any }): Promise<{ category: string, j: Translation[] }> => {
                return YukiApi.get<Response>(`translations/${this.selectedLanguage}/${category.path}`, { responseAs: 'response', query: category.query, }).then(e => {
                    allCachable = allCachable && e.headers.get("X-Is-Cachable") === 'true';
                    return e.json().then(e => {
                        return { category: category.path, j: e };
                    });
                });
            }

            const code = lang ?? this.selectedLanguage;

            const translationRequests = staticTranslations.map((category) => loadTranslation(category));

            try {
                const n = await Promise.allSettled(translationRequests);

                this.$patch((state) => {
                    for (const [cat, n] of state.data) {
                        for (const [key, n2] of n) {
                            if (!isStaticTranslation(cat, key, '')) {
                                n.delete(key);
                            }
                        }
                    }
                    for (const i of n) {
                        if (i.status == "fulfilled") {
                            const translationResult = i.value;
                            let c = toRaw(state.data.get(translationResult.category));
                            if (!c) {
                                c = new Map();
                            }
                            var obj = translationResult.j.reduce(function (acc, cur, i) {
                                let c = acc.get(String(cur.id));
                                if (!c) {
                                    c = new Map();
                                    acc.set(String(cur.id), c);
                                }
                                c.set(cur.key, cur.text);
                                return acc;
                            }, c);
                            state.data.set(translationResult.category, new Map(obj));
                        }
                    }
                });
            } catch (error) {
                console.error('Failed to load all translations...retrying', error);
                setTimeout(() => {
                    this.fetchStaticTranslations(code);
                }, 5 * 1000);
            }

            if (!allCachable) {
                console.debug('Some translations were not cachable, try again');
                // Servers are likely deploying right now, try again in a few seconds
                // We still have loaded all the new data, hoping things don't go wrong :sweat_smile:
                // Reschedule data fetch in 5 seconds
                setTimeout(() => {
                    this.fetchStaticTranslations(code);
                }, 5 * 1000);
            }
        }
    }
});

export function isStaticTranslation(category: string, id: string | number, key: string) {
    for (const n of staticTranslations) {
        if (n.path == category) {
            if (!n.query || n.query['key'] == key) {
                return true;
            }
        }
    }
    return false;
}