<template>
  <img :src="thumbImage" />
</template>

<script lang="ts">
import { computed, defineComponent, unref } from "vue";
import { officerThumb } from "~/lib/thumbs";

export default defineComponent({
  name: "OfficerThumb",
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    return {
      thumbImage: computed(() => unref(officerThumb(props.id))),
    };
  },
});
</script>
