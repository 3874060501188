<template>
  <number-input
    :value="filter"
    @update:value="handleInput"
    :min="min"
    :max="max"
    background="bg-transparent dark:bg-transparent"
    backgroundHover=""
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import NumberInput from "../ui/NumberInput.vue";
import TextToggle from "../ui/TextToggle.vue";

export default defineComponent({
  name: "NumberFilter",
  components: {
    TextToggle,
    NumberInput,
  },
  props: {
    filter: {
      type: Number,
      required: true,
    },
    min: {
      type: [Number, String],
    },
    max: {
      type: [Number, String],
    }
  },
  methods: {
    handleInput(e: any) {
      this.$emit("update:filter", e);
    },
  },
});
</script>
