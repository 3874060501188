<template>
  <list-item wide>
    <template v-slot:image>
      <faction-thumb
        class="object-contain"
        width="64"
        height="64"
        :style="{ maxHeight: '64px' }"
        :id="item.faction"
      />
    </template>
    <template v-slot:name>
      <div class="inline-flex items-center truncate gap-2">
        <img
          :src="hostileIcon"
          class="object-contain h-4 w-4 fix-light-image"
        />
        <rarity-label :rarity="item.rarity"></rarity-label>
        <yuki-text
          class="truncate font-bold"
          skey="name"
          :id="item.loca_id"
          category="hostiles"
        />
        <span class="text-base">({{ item.level }})</span>
      </div>
    </template>
    <template v-slot:sub>
      Power: {{ formatDecimalNumber(item.strength || 0) }} | Warp:
      {{ item.warp || lowestWrapForHostile(item) }}
    </template>
    <template v-slot:extra>
      <div class="grid grid-cols-max-2 grid-rows-3">
        <template v-for="rep in item.reps" :key="rep.resource_id">
          <resource-thumb class="w-4 h-4" :id="rep.resource_id" />
          <span
            class="text-sm tabular-nums text-right"
            :style="{ width: '8ch' }"
            :class="[
              rep.max > 0
                ? 'text-green-700 dark:text-green-200'
                : 'text-red-700 dark:text-red-200',
            ]"
            >{{ formatRep(rep.max) }}</span
          >
        </template>
      </div>
    </template>
  </list-item>
</template>

<script lang="ts">
import { computed, defineComponent, Prop, PropType } from "vue";

import ListItem from "../ui/ListItem.vue";

import armadaIcon from "~/assets/icons/ship_types/armada.png";
import battleShipIcon from "~/assets/icons/ship_types/battleship.png";
import explorerIcon from "~/assets/icons/ship_types/explorer.png";
import interceptorIcon from "~/assets/icons/ship_types/interceptor.png";
import surveyIcon from "~/assets/icons/ship_types/survey.png";

import ResourceThumb from "../thumbs/ResourceThumb.vue";
import FactionThumb from "../thumbs/FactionThumb.vue";
import { Hostile, HullType, ResourceRewardRange } from "~/lib/yuki/models";
import { formatDecimalNumber } from "~/lib/formatters";
import RarityLabel from "../generic/RarityLabel.vue";

import { useYukiDataStore } from "~/stores";

export default defineComponent({
  components: {
    ListItem,
    ResourceThumb,
    FactionThumb,
    RarityLabel,
  },
  props: {
    item: {
      type: Object as PropType<Hostile & { reps: ResourceRewardRange[] }>,
      required: true,
    },
  },
  setup(props) {
    const dataStore = useYukiDataStore();
    const lowestWrapForHostile = (hostile: Hostile) => {
      return (
        dataStore.systems.find((x) => x.id == hostile.systems[0])?.est_warp || 0
      );
    };

    const formatRep = (v: number) => {
      return (v > 0 ? "+" : "") + formatDecimalNumber(v);
    };

    const hostileIcon = computed(() => {
      switch (props.item.hull_type) {
        case HullType.Destroyer:
          return interceptorIcon;
        case HullType.Survey:
          return surveyIcon;
        case HullType.Explorer:
          return explorerIcon;
        case HullType.Battleship:
          return battleShipIcon;
        case HullType.Armada:
          return armadaIcon;
      }
      return "";
    });

    return {
      hostileIcon,

      lowestWrapForHostile,
      formatDecimalNumber,
      formatRep,
    };
  },
});
</script>
