<template>
  <div class="w-72 top-16">
    <Listbox
      :modelValue="selectedOption"
      @update:modelValue="updateSelectedOption"
      v-slot="{ open }"
    >
      <div class="relative">
        <ListboxButton
          class="relative input-bg w-full pl-3 pr-10 min-h-8 text-left rounded cursor-default input-focus sm:text-sm"
        >
          <span class="block truncate">{{ selectedOption.name }}</span>
          <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <SelectorIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
          </span>
        </ListboxButton>

        <transition
          leave-active-class="transition duration-100 ease-in"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <ListboxOptions
            class="z-30 absolute w-full py-1 mt-1 overflow-auto text-base input-bg rounded shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          >
            <ListboxOption
              v-slot="{ active, selected }"
              v-for="option in options"
              :key="option.name"
              :value="option"
              as="template"
            >
              <li
                :class="[
                  active ? 'text-indigo-600 dark:text-indigo-500' : '',
                  'cursor-default select-none relative py-1.5 pl-3 pr-4',
                ]"
              >
                <span
                  :class="[
                    selected ? 'font-medium' : 'font-normal',
                    'block truncate',
                  ]"
                >{{ option.name }}</span>
                <span v-if="selected" class="absolute inset-y-0 right-0 flex items-center pr-4">
                  <CheckIcon class="w-5 h-5" aria-hidden="true" />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>
  </div>
</template>

<script lang="ts">
import { ref, PropType, defineComponent, computed } from "vue";
import {
  Listbox,
  ListboxLabel,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/vue";
import { CheckIcon, SelectorIcon } from "@heroicons/vue/solid";

import isEqual from "lodash/isEqual";

export default defineComponent({
  components: {
    Listbox,
    ListboxLabel,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    CheckIcon,
    SelectorIcon,
  },

  props: {
    options: {
      type: Object as PropType<Array<{ name: string; value: any }>>,
      required: true,
    },
    modelValue: {},
  },

  setup(props, { emit }) {
    const updateSelectedOption = (e: any) => {
      emit("update:modelValue", e.value);
    };

    let search = ref("");

    const selectedOption = computed(() => {
      return (
        props.options.find((x) => {
          return isEqual(x.value, props.modelValue)
        }) ??
        props.options[0]
      );
    });

    return {
      search,
      selectedOption,
      updateSelectedOption,
    };
  },
});
</script>