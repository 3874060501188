<template>
  <header>
    <navbar @toggleDarkTheme="toggleDarkTheme" />
  </header>
  <main class="mx-0 xs2:mx-1 sm:mx-8 max-w-screen-lg justify-self-start self-center w-stretch">
    <router-view />
  </main>
  <footer class="p-2 bg-gray-900 text-gray-500 mt-auto">
    <div class="flex justify-center items-center gap-2">
      <span>
        Feedback or Suggestions? Join
        <a class="text-gray-200" target="_blank" href="https://discord.gg/BvjM6JCcdp">Discord</a>
      </span>
      |
      <span>Version: {{ version }}</span>
      <!-- <span>🧙🍪🌲🍰😮</span> -->
    </div>
  </footer>

  <!-- These are modal dialogs, so they are outside the regular page flow -->
  <ReloadPrompt />
</template>

<script lang="ts">
import { defineComponent, watch, unref } from "vue";
import { useRoute } from "vue-router";

import CategoryCard from "./components/CategoryCard.vue";
import ReloadPrompt from "./components/ReloadPrompt.vue";
import Navbar from "./components/Navbar.vue";

import { SpeakerphoneIcon, XIcon } from "@heroicons/vue/outline";

import "./index.css";
import 'floating-vue/dist/style.css'
import { updateWindowTitle } from "~/router";
import { useYukiDataStore, useYukiTranslationStore } from "./stores";
import { useYukiSettings } from "./stores/settings";

export default defineComponent({
  name: "App",
  components: {
    CategoryCard,
    ReloadPrompt,
    Navbar,
    XIcon,
  },
  setup() {
    const route = useRoute();

    const translationStore = useYukiTranslationStore();
    const settings = useYukiSettings();
    const dataStore = useYukiDataStore();

    translationStore.$onAction(
      ({
        name,
        after,
      }) => {
        after(() => {
          if (name == 'fetchStaticTranslations' || name == 'doFetch') {
            updateWindowTitle(route);
          }
        });
      }
    );
    watch(translationStore.$state, () => {
      updateWindowTitle(route);
    });

    watch(dataStore.$state, () => {
      updateWindowTitle(route);
    });

    if (unref(settings.isDarkTheme)) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
    settings.$onAction(
      ({
        name,
        store,
        after,
      }) => {
        after(() => {
          if (name == 'setDarkTheme' || name == 'setForcedTheme') {
            if (unref(store.isDarkTheme)) {
              document.documentElement.classList.add("dark");
            } else {
              document.documentElement.classList.remove("dark");
            }
          }
        });
      }
    );

    dataStore.fetchVersion().then(() => translationStore.fetchStaticTranslations());

    return {
      version: import.meta.env.PACKAGE_VERSION,
    }
  },
  created() {
    const matchMedia = window.matchMedia("(prefers-color-scheme: dark)");
    if (matchMedia && 'addEventListener' in matchMedia) {
      matchMedia.addEventListener("change", (e) => {
        const settings = useYukiSettings();
        if (settings.forcedTheme) {
          return;
        }
        let isDarkTheme = e.matches;
        settings.setDarkTheme(isDarkTheme);
        if (isDarkTheme) {
          document.documentElement.classList.add("dark");
        } else {
          document.documentElement.classList.remove("dark");
        }
      });
    }
  },
  methods: {
    toggleDarkTheme() {
      const settings = useYukiSettings();
      settings.setForcedTheme(true);
      settings.setDarkTheme(!unref(settings.isDarkTheme));
    },
  },
});
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
}
</style>