<template>
  <span
    v-if="rarity == 'Common' || rarity == 1"
    class="
      mr-1
      px-2
      inline-flex
      items-center
      text-xs
      leading-5
      font-bold
      rounded-full
      bg-gray-100
      text-gray-800
    "
    role="img"
    aria-label="Common"
    >C</span
  >
  <span
    v-if="rarity == 'Uncommon' || rarity == 2"
    class="
      mr-1
      px-2
      inline-flex
      items-center
      text-xs
      leading-5
      font-bold
      rounded-full
      bg-green-300
      text-gray-800
    "
    role="img"
    aria-label="Uncommon"
    >U</span
  >
  <span
    v-if="rarity == 'Rare' || rarity == 3"
    class="
      mr-1
      px-2
      inline-flex
      items-center
      text-xs
      leading-5
      font-bold
      rounded-full
      bg-blue-300
      text-gray-800
    "
    role="img"
    aria-label="Rare"
    >R</span
  >
  <span
    v-if="rarity == 'Epic' || rarity == 4"
    class="
      mr-1
      px-2
      inline-flex
      items-center
      text-xs
      leading-5
      font-bold
      rounded-full
      text-gray-800
    "
    :style="{'background': 'hsl(282deg 87% 75%)'}"
    role="img"
    aria-label="Epic"
    >E</span
  >
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "RarityLabel",
  props: {
    rarity: {
      type: [String, Number],
      required: true,
    },
  },
});
</script>
