import { LocationQueryValue, useRoute, useRouter } from "vue-router";
import { Ref, ref, reactive, watchEffect, watch, onMounted, nextTick, UnwrapRef, toRef } from "vue";;

import URLON from "~/lib/urlon";

function isObject(v: any): v is object {
  return Object(v) === v
}

export declare type ReturnType<T> = T extends (string | number | boolean) ? Ref<T> : T;
declare type ReturnType2<T> = T extends Array<infer Y> ? Ref<ReturnType<T>> : ReturnType<T>;


export class RouteQueryStore {

  private queries = new Map<String, any>();
  private values = new Map<String, any>();
  private lastRoute = '';
  private nextRoute = '';
  private routePath = '';

  public constructor(readonly route = useRoute(), readonly router = useRouter()) {
    this.routePath = route.path;
    onMounted(() => {
      this.ApplyRoutes();
      nextTick(() => {
        this.lastRoute = `${this.route.path}+${JSON.stringify(route.query)}`;
      });
    });

    watch(route, () => {
      nextTick(() => {
        if (this.route.path !== this.routePath) {
          return;
        }
        const currentRoute = `${this.route.path}+${JSON.stringify(route.query)}`;
        if (this.nextRoute == currentRoute) {
          return;
        }
        for (const [_, updateValue] of this.values) {
          updateValue();
        }
      });
    });
  }

  public UpdateRoute() {
    this.routePath = this.route.path;
  }

  private ApplyRoutes() {
    let is_dirty = 0;
    let routeQuery = { ...this.route.query };
    for (const [_, query] of this.queries) {
      is_dirty |= Number(query(routeQuery));
    }

    if (is_dirty != 0) {
      this.nextRoute = `${this.route.path}+${JSON.stringify(routeQuery)}`;
      this.router.replace({
        path: this.route.path,
        query: routeQuery,
      });
    }
  }

  public PersistentQueryStore<T>(context: string,
    name: string,
    defaults: T): ReturnType2<T> {
    let initials: any = undefined;
    try {
      const l_value = window.localStorage.getItem(`${context}.${name}`);
      if (l_value) {
        initials = JSON.parse(l_value as string);
      }
    } catch (e) {
      console.error(e);
    }

    const value = this.QueryStore(name, defaults, initials);
    watch(value as any, (value) => {
      try {
        window.localStorage.setItem(`${context}.${name}`, JSON.stringify(value));
      } catch (e) {
        // This can fail in certain icognito modes, like safari
      }
    }, { deep: true });
    return value;
  }

  public QueryStore<T>(name: string,
    defaultValue: T,
    initial?: any): ReturnType2<T> {
    const router = useRouter();
    const route = useRoute();
    const isPrimitive = !isObject(defaultValue);
    const isArray = Array.isArray(defaultValue);

    let query_data: any = initial;
    let filter: any = isPrimitive || isArray ? ref({}) : reactive({});
    const updateValue = () => {
      if (!isPrimitive) {
        try {
          const qdata = route.query[name] as string;
          if (isArray) {
            query_data = qdata ? URLON.parse(qdata) : undefined;
          } else {
            query_data = Object.assign(initial ?? {}, qdata ? URLON.parse(qdata) : {});
          }
        } catch (e) {
          if (query_data === undefined) {
            query_data = defaultValue;
          }
        }

        const cleanedObject: any = isArray ? [] : {};
        if (!isArray) {
          for (const k in defaultValue) {
            if (k.startsWith("_")) continue;
            cleanedObject[k] = query_data === undefined || query_data[k] === undefined ? defaultValue[k] : query_data[k];
          }
        }
        if (isArray) {
          filter.value = query_data ?? defaultValue;
        } else {
          const newObject = Object.assign({ ...defaultValue as Object }, cleanedObject);
          for (const n in newObject) {
            filter[n] = newObject[n];
          }
        }
      } else {
        const starting_value = (): string | number | boolean => {
          if (Array.isArray(router.currentRoute.value.query[name])) {
            return (defaultValue as unknown as string | number | boolean);
          }
          const strValue = (() => {
            if (router.currentRoute.value.query[name] !== undefined) {
              return router.currentRoute.value.query[name];
            }
            if (initial !== undefined) {
              return initial;
            }

            if (defaultValue !== undefined) {
              return defaultValue;
            }

            console.error('Query data store is missing data');
          })().toString();
          if (typeof defaultValue === 'string') {
            return strValue.toString();
          } else if (typeof defaultValue === 'number') {
            return parseInt(strValue);
          } else if (typeof defaultValue === 'boolean') {
            return strValue === 'true' || strValue === '1';
          }
          return strValue;
        };
        filter.value = starting_value();
      }
    };

    // Load the inital value from query, inital provided value or default
    updateValue();

    let updateRoute = (query: any) => {
      let encoded;
      if (isPrimitive) {
        if (typeof defaultValue === 'boolean') {
          encoded = filter.value ? '1' : '0';
        } else {
          encoded = filter.value.toString();
        }
      } else {
        if (isArray) {
          encoded = URLON.stringify(filter.value);
        } else {
          encoded = URLON.stringify(filter);
        }
      }

      // Prevent duplicate navigation warnings...I am not sure why this happens (yet)
      // This hack should be good enough for now
      // TODO(alexander): Find the root cause
      if (query[name] == encoded) {
        return false;
      }
      query[name] = encoded;
      return true;
    };

    this.queries.set(name, updateRoute);
    this.values.set(name, updateValue);

    watch(filter, () => {
      this.ApplyRoutes();
    });

    return filter;
  }
};

export function urlStringify<T extends Object>(t: T) {
  return URLON.stringify(t);
}