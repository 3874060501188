<template>
  <div class="flex flex-col gap-x-2 min-w-fit">
    <div class="flex justify-between gap-4">
      <span class="font-bold flex items-center">
        <ClockIcon class="h-5 w-5 inline mr-1" aria-hidden="true" />Time
      </span>
      <span class="font-bold tabular-nums">
        {{
          prettyMilliseconds(time * 1000)
        }}
      </span>
    </div>
    <div class="flex justify-between gap-4">
      <span class="font-bold flex items-center">
        <ClockIcon class="h-5 w-5 inline mr-1" aria-hidden="true" />Seconds
      </span>

      <span class="font-bold tabular-nums">
        {{
          formatGroupedNumber(time)
        }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import prettyMilliseconds from "pretty-ms";

import { ClockIcon } from "@heroicons/vue/solid";
import { formatGroupedNumber } from "~/lib/formatters";

export default defineComponent({
  name: "TimeDisplay",
  components: {
    ClockIcon,
  },
  props: {
    time: {
      type: Number,
      required: true,
    },
  },
  setup() {
    return { prettyMilliseconds, formatGroupedNumber };
  },
});
</script>
