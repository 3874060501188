<template>
  <list-item>
    <template v-slot:image>
      <building-thumb width="64" height="64" :id="item.id" />
    </template>
    <template v-slot:name>
      <div class="inline-flex items-center truncate">
        <yuki-text
          class="truncate font-bold"
          skey="name"
          :id="item.id"
          category="buildings"
        />
      </div>
    </template>
    <template v-slot:sub>
      Unlock Level: {{item.unlock_level}}
    </template>
  </list-item>
</template>

<script lang="ts">
import { defineComponent, Prop, PropType } from "vue";

import ListItem from "../ui/ListItem.vue";

import housingIcon from "~/assets/icons/misc/housing.png";
import missionIcon from "~/assets/icons/misc/mission.png";
import deepSpaceIcon from "~/assets/icons/misc/deepspace.png";

import armadaIcon from "~/assets/icons/ship_types/armada.png";
import battleShipIcon from "~/assets/icons/ship_types/battleship.png";
import explorerIcon from "~/assets/icons/ship_types/explorer.png";
import interceptorIcon from "~/assets/icons/ship_types/interceptor.png";
import surveyIcon from "~/assets/icons/ship_types/survey.png";

import ResourceThumb from "../thumbs/ResourceThumb.vue";
import FactionThumb from "../thumbs/FactionThumb.vue";
import BuildingThumb from "../thumbs/BuildingThumb.vue";
import { Building } from "~/lib/yuki/models";

export default defineComponent({
  components: {
    ListItem,
    ResourceThumb,
    FactionThumb,
    BuildingThumb,
  },
  props: {
    item: {
      type: Object as PropType<Building>,
      required: true,
    },
  },
  setup(props) {
    return {
      housingIcon,
      missionIcon,
      deepSpaceIcon,
      armadaIcon,
      battleShipIcon,
      explorerIcon,
      interceptorIcon,
      surveyIcon,
    };
  },
});
</script>
