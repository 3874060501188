<template>
  <div class="flex justify-between">
    <item-line-grid :item="item" :left="left" />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import { Reward, Requirement, BuildCost } from "~/lib/yuki/models";

import ItemLineGrid from "./ItemLineGrid.vue";

export default defineComponent({
  name: "ItemLine",
  components: {
    ItemLineGrid,
  },
  props: {
    left: {
      type: Boolean,
    },
    item: {
      type: Object as PropType<
        | BuildCost
        | Requirement
        | Reward
        | { resource_id: number; amount: number }
      >,
      required: true,
    },
  },
});
</script>
