import { createRouter, createWebHistory, RouteLocationNormalized, RouteLocationNormalizedLoaded, RouterView } from "vue-router";
const Home = () => import("~/components/pages/Home.vue");
const Ships = () => import("~/components/pages/lists/Ships.vue");
const Ship = () => import("~/components/pages/detail/Ship.vue");
const Officers = () => import("~/components/pages/lists/Officers.vue");
const Officer = () => import("~/components/pages/detail/Officer.vue");
const Researches = () => import("~/components/pages/lists/Researches.vue");
const Researche = () => import("~/components/pages/detail/Research.vue");
const Buildings = () => import("~/components/pages/lists/Buildings.vue");
const Building = () => import("~/components/pages/detail/Building.vue");
const Systems = () => import("~/components/pages/lists/Systems.vue");
const System = () => import("~/components/pages/detail/System.vue");
const Hostile = () => import("~/components/pages/detail/Hostile.vue");
const Hostiles = () => import("~/components/pages/lists/Hostiles.vue");
const Missions = () => import("~/components/pages/lists/Missions.vue");
const Mission = () => import("~/components/pages/detail/Mission/index.vue");
const MissionHostile = () => import("~/components/pages/detail/Mission/Hostile.vue");
const Syndicate = () => import("~/components/pages/detail/Syndicate.vue");
const PageNotFound = () => import("~/components/pages/404.vue");
const Auth = () => import("~/components/pages/Auth.vue");
const Events = () => import("~/components/pages/lists/Events.vue");
const Event = () => import("~/components/pages/detail/Event.vue");
const Backers = () => import("~/components/pages/Backers.vue");
const ArcRewards = () => import("~/components/pages/tools/ArcRewards.vue");

export enum ItemType {
  System = 1,
  Ship,
  Research,
  Building,
  Officer,
  Hostile,
  Mission,
  MissionHostile,
  Event,
}

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { breadCrumb: "Home" },
  },
  {
    path: "/backers",
    name: "Backers",
    component: Backers,
    meta: { breadCrumb: "Backers" },
  },
  {
    path: "/systems",
    name: "Systems",
    component: RouterView,
    meta: { breadCrumb: "Systems" },
    children: [
      {
        path: "",
        name: "Systems",
        component: Systems,
        meta: { breadCrumb: "Systems" },
      },
      {
        path: ":id",
        name: "System",
        component: System,
        props: true,
        meta: { type: ItemType.System, remote: true },
      },
    ]
  },
  {
    path: "/ships",
    name: "Ships",
    component: RouterView,
    meta: { breadCrumb: "Ships" },
    children: [
      {
        path: "",
        name: "Ships",
        component: Ships,
        meta: { breadCrumb: "Ships" },
      },
      {
        path: ":id",
        name: "Ship",
        component: Ship,
        props: true,
        meta: { type: ItemType.Ship, remote: true },
      },
    ]
  },
  {
    path: "/researches",
    name: "Researches",
    component: RouterView,
    meta: { breadCrumb: "Researches" },
    children: [
      {
        path: "",
        name: "Researches",
        component: Researches,
        meta: { breadCrumb: "Researches" },
      },
      {
        path: ":id",
        name: "Research",
        component: Researche,
        props: true,
        meta: { type: ItemType.Research, remote: true },
      },
    ]
  },
  {
    path: "/buildings",
    name: "Building",
    component: RouterView,
    props: true,
    meta: { breadCrumb: "Buildings", type: ItemType.Building, remote: true },
    children: [
      {
        path: "",
        name: "Buildings",
        component: Buildings,
        meta: { breadCrumb: "Buildings" },
      },
      {
        path: ":id",
        name: "Building",
        component: Building,
        props: true,
        meta: { type: ItemType.Building, remote: true },
      }
    ]
  },

  {
    path: "/officers",
    name: "Officers",
    component: RouterView,
    meta: { breadCrumb: "Officers" },
    children: [
      {
        path: "",
        name: "Officers",
        component: Officers,
        meta: { breadCrumb: "Officers" },
      },
      {
        path: ":id",
        name: "Officer",
        component: Officer,
        props: true,
        meta: { type: ItemType.Officer, remote: true },
      },
    ]
  },
  {
    path: "/hostiles",
    name: "Hostiles",
    component: RouterView,
    meta: { breadCrumb: "Hostiles" },
    children: [
      {
        path: "",
        name: "Hostiles",
        component: Hostiles,
        meta: { breadCrumb: "Hostiles" },
      },
      {
        path: ":id",
        name: "Hostile",
        component: Hostile,
        props: true,
        meta: { breadCrumb: "Hostiles", type: ItemType.Hostile, remote: true },
      },
    ]
  },

  {
    path: "/missions",
    name: "Missions",
    component: RouterView,
    meta: { breadCrumb: "Missions" },
    children: [
      {
        path: "",
        name: "Missions",
        component: Missions,
        props: true,
        meta: { breadCrumb: "Missions" },
      },
      {
        path: ":mid",
        name: "Mission",
        component: RouterView,
        meta: { type: ItemType.Mission, remote: true },
        children: [
          {
            path: "",
            name: "Mission",
            component: Mission,
            props: true,
            meta: {
              type: ItemType.Mission, remote: true
            },
          },
          {
            path: "hostile/:id",
            name: "MissionMissionHostile",
            component: MissionHostile,
            props: true,
            meta: {
              type: ItemType.MissionHostile, remote: true
            },
          }
        ]
      },
    ]
  },
  {
    path: '/events',
    name: 'Events',
    component: RouterView,
    meta: { breadCrumb: "Events" },
    children: [
      {
        path: '/events',
        name: 'Events',
        component: Events,
        meta: { breadCrumb: "Events" },
      },
      {
        path: ':id',
        name: 'Event',
        component:
          Event,
        props: true,
        meta: { type: ItemType.Event, remote: true },
      },
    ]
  },
  { path: "/syndicate", component: Syndicate, meta: { breadCrumb: "Syndicate" }, },
  { path: "/arc/rewards", component: ArcRewards, meta: { breadCrumb: "Arc Rewards" } },
  { path: "/auth", component: Auth },
  { path: "/:pathMatch(.*)*", component: PageNotFound },
];
const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (to.path == from.path) {
      return Promise.resolve(
        savedPosition
          ? savedPosition
          : { left: window.scrollX, top: window.scrollY }
      );
    } else {
      if (to.hash) {
        return new Promise((resolve, reject) => {
          const scrollTo = {
            el: to.hash,
            behavior: 'smooth',
          };
          if (to.meta.remote) {
          } else {
            setTimeout(() => resolve(scrollTo as any), 10);
          }
        });
      }
      return Promise.resolve(
        savedPosition ? savedPosition : { left: 0, top: 0 }
      );
    }
  },
  routes,
});

import { useYukiDataStore } from "~/stores";
import { getStaticTranslation } from "~/lib/yuki/utils";

export function itemName(ids: string, type?: ItemType) {
  if (!type) {
    return ids;
  }
  if (!ids) {
    return ids;
  }
  try {
    switch (type) {
      case ItemType.Building: {
        return getStaticTranslation("buildings", ids, "name");
      }
      case ItemType.Officer: {
        return getStaticTranslation("officers", ids, "name");
      }
      case ItemType.Research: {
        return getStaticTranslation("research", ids, "name");
      }
      case ItemType.Ship: {
        return getStaticTranslation("ships", ids, "name");
      }
      case ItemType.System: {
        return getStaticTranslation("systems", ids, "name");
      }
      case ItemType.Mission: {
        return getStaticTranslation("missions", ids, "title");
      }
      case ItemType.Hostile: {
        const dataStore = useYukiDataStore();
        const id = parseInt(ids);
        const hostile = dataStore.hostiles.find(x => x.id == id);
        return getStaticTranslation("hostiles", hostile?.loca_id || 0, "name") || 'Hostile Name Missing';
      }
      case ItemType.MissionHostile: {
        return getStaticTranslation('mission_hostiles', ids, 'name');
      }
      case ItemType.Event: {
        return '';
        return getStaticTranslation('events', ids, 'title');
      }
    }
  } catch {
    return ids;
  }
}

function titleFromPath(path: RouteLocationNormalizedLoaded) {
  const parts = path.path.split("/");
  const lastPart = parts[parts.length - 1];
  const iName = itemName(lastPart, path.meta.type as ItemType);
  const title = iName == lastPart ? path.meta.breadCrumb : iName;
  window.document.title = `STFC Database - ${title ?? "Not Found"}`;
}

export function updateWindowTitle(route: RouteLocationNormalizedLoaded) {
  titleFromPath(route);
}

router.beforeEach((to, from, next) => {
  titleFromPath(to);
  next();
});

export default router;
