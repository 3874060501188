<template>
  <span
    class="
      relative
      px-1
      flex
      justify-center
      items-center
      first:rounded-l
      last:rounded-r
      ring-1 ring-inset
      -ml-px
      slast:-ml-0
    "
    @click.stop.prevent="updateChecked"
    :class="
      checked
        ? 'z-20 shadow-inner ring-indigo-500'
        : ' ring-gray-300 dark:ring-gray-500 z-10 '
    "
  >
    <input
      type="checkbox"
      class="appearance-none sr-only"
      :id="id"
      :checked="checked"
    />
    <label
      :class="checked ? 'text-green-500' : ''"
      class="select-none"
      :for="id"
      ><slot>{{ label }}</slot></label
    >
  </span>
</template>

<script lang="ts">
import { ref, reactive, defineComponent } from "vue";

export default defineComponent({
  name: "TextToggle",
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    checked: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    updateChecked() {
      this.$emit("update:checked", !this.checked);
    },
  },
});
</script>
