<template>
  <list-item>
    <template v-slot:image>
      <faction-thumb
        :id="item.faction"
        class="object-contain"
        width="64"
        height="64"
        :style="{ maxHeight: '64px' }"
      />
    </template>
    <template v-slot:icons>
      <img
        :src="housingIcon"
        class="object-contain"
        :class="{ 'h-0': !item.has_player_containers }"
        width="16"
        height="16"
      />
      <img
        :src="missionIcon"
        class="object-contain"
        :class="{ 'h-0': !item.has_missions }"
        width="16"
        height="16"
      />
      <img
        :src="deepSpaceIcon"
        :class="{ 'h-0': !item.is_deep_space }"
        class="object-contain"
        width="16"
        height="16"
      />
    </template>
    <template v-slot:name>
      <span>
        <yuki-text
          class="font-bold truncate"
          skey="name"
          :id="item.id"
          category="systems"
        />
        <span class="text-sm font-bold"> ({{ item.level }})</span>
      </span>
    </template>
    <template v-slot:sub>
      <span class="flex gap-2 items-center">
        <resource-thumb
          v-for="mine in item.mine_resources"
          class="my-auto"
          width="24"
          height="24"
          :id="mine"
          :key="mine"
        />
      </span>
    </template>
    <template v-slot:extra>
      <span class="text-sm whitespace-nowrap"
        >Warp: <span class="font-bold">{{ item.est_warp }}</span></span
      >
      <div class="mt-2 grid grid-cols-5 h-4 w-24 gap-1 min-w-max">
        <img
          v-if="item.hostile_types.arm"
          :src="armadaIcon"
          class="object-contain h-4 w-4 fix-light-image"
          :class="{ 'h-0': !item.hostile_types.arm }"
        />
        <img
          v-if="item.hostile_types.bs"
          :src="battleShipIcon"
          class="object-contain h-4 w-4 fix-light-image"
          :class="{ 'h-0': !item.hostile_types.bs }"
        />
        <img
          v-if="item.hostile_types.exp"
          :src="explorerIcon"
          class="object-contain h-4 w-4 fix-light-image"
          :class="{ 'h-0': !item.hostile_types.exp }"
        />
        <img
          v-if="item.hostile_types.int"
          :src="interceptorIcon"
          class="object-contain h-4 w-4 fix-light-image"
          :class="{ 'h-0': !item.hostile_types.int }"
        />
        <img
          v-if="item.hostile_types.sur"
          :src="surveyIcon"
          class="object-contain h-4 w-4 fix-light-image"
          :class="{ 'h-0': !item.hostile_types.sur }"
        />
      </div>
    </template>
  </list-item>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import ListItem from "../ui/ListItem.vue";

import housingIcon from "~/assets/icons/misc/housing.png";
import missionIcon from "~/assets/icons/misc/mission.png";
import deepSpaceIcon from "~/assets/icons/misc/deepspace.png";

import armadaIcon from "~/assets/icons/ship_types/armada.png";
import battleShipIcon from "~/assets/icons/ship_types/battleship.png";
import explorerIcon from "~/assets/icons/ship_types/explorer.png";
import interceptorIcon from "~/assets/icons/ship_types/interceptor.png";
import surveyIcon from "~/assets/icons/ship_types/survey.png";

import ResourceThumb from "../thumbs/ResourceThumb.vue";
import FactionThumb from "../thumbs/FactionThumb.vue";
import { System } from "~/lib/yuki/models";

export default defineComponent({
  components: {
    ListItem,
    ResourceThumb,
    FactionThumb,
  },
  props: {
    item: {
      type: Object as PropType<
        System & {
          hostile_types: {
            int: boolean;
            bs: boolean;
            exp: boolean;
            arm: boolean;
            sur: boolean;
          };
        }
      >,
      required: true,
    },
  },
  setup(props) {
    return {
      housingIcon,
      missionIcon,
      deepSpaceIcon,
      armadaIcon,
      battleShipIcon,
      explorerIcon,
      interceptorIcon,
      surveyIcon,
    };
  },
});
</script>
