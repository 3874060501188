<template>
  <div class="flex flex-col w-full items-center mb-4">
    <span :id="anchorTag" @click="toggleCollapsed" v-if="title" class="group section-title">
      <a
        class="p-2 group-hover:text-gray-700 dark:group-hover:text-gray-200 text-transparent"
        aria-hidden="true"
        :href="'#' + anchorTag"
        @click.stop
      >
        <LinkIcon class="h-4 w-4" />
      </a>
      {{ title }}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        class="h-5 w-5 mt-1 ml-1"
        stroke="currentColor"
        v-if="canCollapse"
      >
        <path
          v-if="!collapsed"
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
        <path
          v-else
          fill-rule="evenodd"
          d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
          clip-rule="evenodd"
        />
      </svg>
    </span>

    <div
      class="text-left mt-4 max-w-prose lg:max-w-screen-md w-full"
      :class="{ 'w-full': full }"
      v-if="!collapsed"
    >
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { ref, defineComponent, watch } from "vue";

import { LinkIcon } from "@heroicons/vue/solid";

export default defineComponent({
  name: "TitledTextSegment",
  props: {
    title: {
      type: String,
      optional: true,
    },
    collapsed: {
      type: Boolean,
      optional: true,
      default: undefined,
    },
    full: {
      type: Boolean
    }
  },
  components: {
    LinkIcon
  },
  setup(props, { emit }) {
    const canCollapse = props.collapsed !== undefined;
    const collapsed = ref(canCollapse ? props.collapsed : false);

    const toggleCollapsed = () => {
      if (!canCollapse) {
        return;
      }

      const newVal = !collapsed.value;
      collapsed.value = newVal;
      emit("update:collapsed", newVal);
    };

    watch(props, () => {
      collapsed.value = props.collapsed as boolean;
    });

    const anchorTag = props.title?.replaceAll(" ", "-").toLocaleLowerCase();

    return { collapsed, toggleCollapsed, anchorTag, canCollapse };
  },
});
</script>
