<template>
  <generic-card>
    <template v-slot:header>
      <span
        class="flex justify-center items-center gap-2 border-b border-opacity-25 dark:border-opacity-25 dark:border-gray-50 border-gray-900 pb-1"
      >
        <img :src="resourceImage" width="32" height="32" />
        <yuki-text skey="name" category="materials" :id="item.resource" />
      </span>
    </template>
    <template v-slot>
      <div
        class="flex flex-col justify-center border-b border-opacity-25 dark:border-opacity-25 dark:border-gray-50 border-gray-900 text-sm pb-1"
      >
        <stat-grid>
          <stat-line name="Size" :value="sizes" />
          <stat-line name="Rate" :value="rates" />
          <stat-line name="Count" :value="count" />
        </stat-grid>
      </div>
    </template>
  </generic-card>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import ItemLine from "./generic/ItemLineGrid.vue";
import GenericCard from "./ui/GenericCard.vue";
import StatLine from "./generic/StatLine.vue";
import { resourceThumb } from "~/lib/thumbs";
import YukiText from "./ui/YukiText.vue";
import StatGrid from "./generic/StatGrid.vue";
import { Mine } from "~/lib/yuki/models";
import { useYukiDataStore } from "~/stores";
import { getStaticTranslation } from "~/lib/yuki/utils";

export default defineComponent({
  name: "MineCard",
  components: {
    ItemLine,
    GenericCard,
    StatLine,
    YukiText,
    StatGrid,
  },
  props: {
    item: {
      type: Object as PropType<Mine>,
      required: true,
    },
    count: {
      type: Number,
    },
    rates: {
      type: Array,
    },
    sizes: {
      type: Array,
    }
  },
  setup(props) {
    const dataStore = useYukiDataStore();
    const resourceImage = resourceThumb(
      dataStore.resources.get(props.item.resource)?.art_id
    );

    const resourceName = getStaticTranslation(
      "materials",
      props.item.resource.toString(),
      "name"
    );

    const rates = props.rates ? props.rates : [props.item.rate];

    return { resourceImage, resourceName, rates };
  },
});
</script>

