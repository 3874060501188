<template>
  <div class="item-header-container">
    <div class="item-header h-fit">
      <slot name="image"></slot>
      <span class="flex flex-col items-start text-sm" :class="{ 'ml-2': hasImage }">
        <slot name="text"></slot>
      </span>
    </div>
    <div v-if="hasAux" class="lg:ml-auto mt-2 lg:mr-16 max-w-level-select">
      <slot name="aux"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "DetailPageHeader",
  setup(props, { slots }) {
    return {
      hasAux: !!slots.aux,
      hasImage: !!slots.image,
    };
  },
});
</script>
