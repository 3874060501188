import { Ref } from "vue";
import { ItemSearchItem, useSearch } from "~/lib/search";
import { useFilterNameHack } from "./useFilterNameHack";


export function useItemSearch<Y>(allItems: Ref<Array<Y & ItemSearchItem>>, fallbackItems: Ref<Array<Y>>, filter?: (item: Y) => boolean) {

    const { filterName, searchField } = useFilterNameHack();

    const filteredItems = useSearch(allItems, filterName, undefined, filter || (() => true), () => fallbackItems.value);

    return {
        searchField,
        filteredItems,
        updateFilterName: (filter: any) => {
            filterName.value = filter.name;
        },
    }
}