<template>
  <div class="min-h-fit">
    <table class="relative w-full divide-y divide-gray-200 dark:divide-gray-500"
    :class="{'md:table-fixed': fixed}">
      <thead class=" sticky top-0 bg-table-header">
        <tr>
          <th
            v-for="header in headers"
            :key="header.text"
            scope="col"
            class="px-2 py-2 text-right text-xs font-medium text-gray-500 dark:text-gray-100 uppercase tracking-wider"
          >{{ header.text }}</th>
        </tr>
      </thead>
      <tbody
        class="bg-white dark:bg-gray-600 divide-y divide-gray-200 dark:divide-gray-500"
        x-max="1"
      >
        <tr v-for="(item, index) in items" :key="index">
          <td
            v-for="(header, index) in headers"
            :key="index"
            class="text-right px-1 py-2 whitespace-nowrap text-sm dark:text-gray-100 tabular-nums"
          >
            <slot :header="header" :item=" item[header.value]">{{ f(header, item[header.value]) }}</slot>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { formatGroupedNumber, formatPercentageNumber } from "~/lib/formatters";

export interface TableHeader {
  text: string;
  value: string;
  percentage?: boolean;
  round?: boolean;
}

export type TableItem = any;

export default defineComponent({
  name: "YTable",
  props: {
    headers: {
      type: Array as PropType<Array<TableHeader>>,
      required: true,
    },
    items: {
      type: Array as PropType<Array<any>>,
      required: true,
    },
    title: {
      type: String,
    },
    fixed: {
      type: Boolean,
    }
  },
  setup() {
    const f = (h: any, v: any) => {
      if (typeof v == "number") {
        if (h.round) v = Math.round(v);
        if (h.percentage) {
          return formatPercentageNumber(v);
        } else {
          return formatGroupedNumber(v);
        }
      } else {
        return v;
      }
    };
    return { f };
  },
});
</script>
<style scoped>
table {
  border-collapse: collapse;
}
th, td {
    word-wrap: break-word;
    word-break: normal;
    overflow-wrap: anywhere;
    hyphens: auto;
}
</style>