<template>
  <div class="flex overflow-x-auto">
    <div class="grid auto-rows-fr border-r px-2 bg-table-header auto-cols-min">
      <div
        class="flex items-between items-center bg-table-header text-center text-right text-xs font-medium text-gray-500 dark:text-gray-100 uppercase tracking-wider text-center"
      >
        <span class="flex ml-2">
          Weapon
          <ChevronDownIcon class="w-4 h-4" />
        </span>
        <span class="flex ml-2">
          Round
          <ChevronRightIcon class="w-4 h-4" />
        </span>
      </div>
      <template v-for="(weapon, index) in sortedWeapons" :key="index + '' + weapon.id">
        <div class="flex flex-col py-1">
          <yuki-text
            class="bg-table-header text-right text-xs font-medium text-gray-800 dark:text-gray-100 uppercase tracking-wider"
            skey="name"
            :id="weapon.loca_id"
            category="ship_components"
            :fallback="`${t('weapon.title')} ${index + 1}`"
          />
          <span class="text-xs ml-auto dark:text-green-300 text-green-500">
            <template v-if="weapon.data.weapon_type == 1">{{ t("weapon.energy") }}</template>
            <template v-else>{{ t("weapon.kinetic") }}</template>
          </span>
        </div>
      </template>
    </div>
    <div
      class="grid grid-cols-15 min-w-fit"
      :style="{
        minInlineSize: 'max-content',
      }"
    >
      <template v-for="round in rounds" :key="round">
        <div
          class="bg-table-header text-center px-3 py-3 text-xs font-medium text-gray-800 dark:text-gray-100 uppercase w-10 tracking-wider flex justify-center items-center"
        >{{ round }}</div>
      </template>
      <template v-for="(weapon, index) in sortedWeapons" :key="index + '' + weapon.id">
        <template v-for="round in rounds" :key="round">
          <div
            class="bg-white dark:bg-gray-600 divide-y divide-gray-200 dark:divide-gray-500 px-2 py-2 whitespace-nowrap text-sm dark:text-gray-100 tracking-wider w-10 flex justify-center items-center"
            v-if="
              round >= weapon.data.warm_up &&
              (round - weapon.data.warm_up) % weapon.data.cool_down == 0
            "
          >{{ weapon.data.shots }}</div>
          <span
            class="bg-white dark:bg-gray-600 divide-y divide-gray-200 dark:divide-gray-500 px-2 py-2 whitespace-nowrap text-sm dark:text-gray-100 tracking-wider w-10 flex justify-center items-center"
            v-else
          >-</span>
        </template>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue";

import { ShipComponentWeapon, ShipDetailComponent } from "~/lib/yuki/models";

import YukiText from "./ui/YukiText.vue";
import { useI18n } from "vue-i18n";

import { ChevronRightIcon, ChevronDownIcon } from "@heroicons/vue/solid";

export default defineComponent({
  name: "ShipFiringPattern",
  components: {
    YukiText,

    ChevronRightIcon,
    ChevronDownIcon,
  },
  props: {
    weapons: {
      type: Array as PropType<Array<ShipDetailComponent>>,
      required: true,
    },
    rounds: {
      type: Number,
      default: 15,
    },
  },
  setup(props) {
    const sortedWeapons = computed((): (ShipDetailComponent & {
      data: ShipComponentWeapon;
    })[] => {
      return props.weapons
        .filter((x) => x.data.tag === "Weapon")
        .map((x) => x as ShipDetailComponent & { data: ShipComponentWeapon })
        .sort((a, b) => a.order - b.order);
    });

    const { t } = useI18n();

    return { t, sortedWeapons };
  },
});
</script>

