<template>
  <img :src="thumbImage" />
</template>

<script lang="ts">
import { computed, defineComponent, unref } from "vue";
import { officerAbilityThumb } from "~/lib/thumbs";

export default defineComponent({
  name: "OfficerAbilityThumb",
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    return {
      thumbImage: computed(() => unref(officerAbilityThumb(props.id))),
    };
  },
});
</script>
