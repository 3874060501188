<template>
  <span class="w-full block">Faction:</span>
  <span class="flex flex-wrap min-h-8 rounded">
    <text-radio id="FactionFilter" label="All" :pick="filter" value="-1" @update:pick="updatePick" />
    <text-radio
      id="FactionFilter"
      label="Federation"
      :pick="filter"
      @update:pick="updatePick"
      value="2064723306"
    />
    <text-radio
      id="FactionFilter"
      label="Romulan"
      :pick="filter"
      @update:pick="updatePick"
      value="669838839"
    />
    <text-radio
      id="FactionFilter"
      label="Klingon"
      :pick="filter"
      @update:pick="updatePick"
      value="4153667145"
    />
    <template v-if="mission">
      <text-radio
        id="FactionFilter"
        label="Rogue"
        :pick="filter"
        @update:pick="updatePick"
        value="2143656960"
      />
    </template>
    <template v-if="extended">
      <text-radio
        id="FactionFilter"
        label="Swarm"
        :pick="filter"
        @update:pick="updatePick"
        value="2489857622"
      />
      <text-radio
        id="FactionFilter"
        label="Borg"
        :pick="filter"
        @update:pick="updatePick"
        value="2943562711"
      />
      <text-radio
        id="FactionFilter"
        label="Eclipse"
        :pick="filter"
        @update:pick="updatePick"
        value="1750120904"
      />
      <!-- <text-radio
        id="FactionFilter"
        label="Assimilated"
        :pick="filter"
        @update:pick="updatePick"
        value="157476182"
      />-->
    </template>
    <template v-if="extended || other">
      <text-radio
        id="FactionFilter"
        label="Other"
        :pick="filter"
        @update:pick="updatePick"
        value="-2"
      />
    </template>
  </span>
</template>

<script setup lang="ts">
import TextRadio from "../ui/TextRadio.vue";

defineProps<{
  filter: number | string;
  extended?: boolean;
  mission?: boolean;
  other?: boolean;
}>();

const emits = defineEmits(["update:filter"]);

const updatePick = (e: any) => {
  emits("update:filter", e);
};
</script>
