<template >
  <router-link :to="to" v-if="to">
    <slot />
  </router-link>
  <span v-else>
    <slot />
  </span>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MaybeLink",
  props: {
    to: {
      type: String,
    },
  },
});
</script>
