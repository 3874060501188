<template>
  <div class="grid grid-cols-max-2 gap-x-2 items-center">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "StatGrid",
});
</script>
