<template>
  <span v-for="buff in buffs" :key="buff.id" class="text-left">
    <slot :buff="buff" />:
    <buff-display :buff="buff" :level="level" />
  </span>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Buff } from "~/lib/yuki/models";

import BuffDisplay from "./BuffDisplay.vue";

export default defineComponent({
  name: "BuffDisplayLust",
  components: {
    "buff-display": BuffDisplay,
  },
  props: {
    buffs: {
      type: Array as PropType<Array<Buff>>,
      required: true,
    },
    level: {
      type: Number,
      required: true,
    },
  },
});
</script>
