<template>
  <component :is="as" v-html="text"></component>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { formatPercentageNumber } from "~/lib/formatters";
import { getStaticTranslation } from "~/lib/yuki/utils";

export function yukiFormat(text: string, ...a: any[]) {
  const args = a;
  return text.replaceAll(
    /{(\d+)(?::((.*?)(.))){0,1}}/g,
    function (match, number, group2, group3, group4) {
      const n = args[number];
      if (n == undefined) {
        return match;
      }
      if (group4 == "%" || group3 == "P") {
        return formatPercentageNumber(n);
      } else {
        return n;
      }
    }
  );
}

function capitalizeFirstLetter(text: string) {
  return text.charAt(0).toLocaleUpperCase() + text.slice(1).toLocaleLowerCase();
}

export default defineComponent({
  name: "YukiText",
  props: {
    category: {
      type: String,
      required: true,
    },
    id: {
      type: [String, Number],
      required: true,
    },
    skey: {
      type: String,
      required: true,
    },
    vars: {
      type: Array,
      required: false,
    },
    fallback: {
      type: String,
    },
    stripLines: {
      type: [String, Number],
    },
    stripLinesBack: {
      type: [String, Number],
    },

    uf: {
      type: Boolean,
    },
    as: {
      type: String,
      required: false,
      default: 'span'
    }
  },
  computed: {
    text(): string | undefined {
      const text = getStaticTranslation(
        this.category,
        this.id.toString(),
        this.skey,
        this.fallback === undefined
      );

      const cleanedText = (
        text
          ? text
            .replaceAll(/<color=\#[A-F0-9]+>/g, "<b>")
            .replaceAll(/<\/color>/g, "</b>")
          : this.fallback || "Missing Translation"
      ).split("\n");

      if (this.stripLines) {
        let steps = this.stripLines;
        if (typeof this.stripLines === "string") {
          steps = parseInt(this.stripLines);
        }
        for (let i = 0; i < steps; ++i) {
          cleanedText.shift();

          if (cleanedText.length > 0 && cleanedText[0].trim().length == 0) {
            (steps as any) += 1;
          }
        }
      }

      if (this.stripLinesBack) {
        let steps = this.stripLinesBack;
        if (typeof this.stripLinesBack === "string") {
          steps = parseInt(this.stripLinesBack);
        }
        for (let i = 0; i < steps; ++i) {
          cleanedText.pop();

          if (cleanedText.length > 0 && cleanedText[cleanedText.length - 1].trim().length == 0) {
            (steps as any) += 1;
          }
        }
      }

      const finalText = cleanedText.join("<br>");
      const cap = this.uf ? capitalizeFirstLetter : (t: string) => t;

      const nText = finalText
        ? yukiFormat(finalText, ...(this.vars ? this.vars : []))
        : this.fallback || "Missing Translation";
      if (this.uf) {
        return nText
          .split(" ")
          .map((x) => cap(x))
          .join(" ");
      } else {
        return nText;
      }
    },
  },
});
</script>
