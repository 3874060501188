<template>
    <div class="flex flex-col w-full items-center">
        <div class="w-full flex gap-2">
            <slot :actions="{  centerToHighlighted, downloadSvg  }"></slot>
        </div>
        <div
            ref="containerRef"
            class="overflow-auto w-full select-none"
            @mousedown="mouseDown"
            :style="{ 'contain': 'layout content' }"
        >
            <svg
                class="mx-auto"
                xmlns="http://www.w3.org/2000/svg"
                :id="id"
                ref="svgRef"
                :height="canvasHeight"
                :width="canvasWidth"
            >
                <svg:style type="text/css">
                    text {
                        font-family: Avenir, Helvetica, Arial, sans-serif;
                        font-size: 14px;
                    }
                    a {
                        color: inherit;
                        text-decoration: inherit;
                    }
                </svg:style>
                <g v-if="layout">
                    <Node
                        v-for="child in layout.children"
                        :node="child"
                        :key="child.id"
                        :highlighted="child.id == props.highlightedNode"
                    />
                    <Edge v-for="edge in layout.edges" :key="edge.id" :edge="edge" />
                </g>
            </svg>
        </div>
    </div>
</template>

<script setup lang="ts">
import { nextTick, onUnmounted, ref, unref, watch } from 'vue';
import { useWindowEvent } from '~/hooks/useWindowEvent';
import { getStaticTranslation } from '~/lib/yuki/utils';
import { useYukiSettings } from '~/stores/settings';

import Edge from "./Edge.vue";
import { ElkRoot, useLayout } from './layout';
import Node from "./Node.vue";

const props = defineProps<{
    nodes?: Array<any>,
    edges?: Array<any>,
    graphData?: any,
    highlightedNode?: any,
}>();

const containerRef = ref<HTMLElement | null>(null);
const svgRef = ref<Node | null>(null);

const canvasHeight = ref(0);
const canvasWidth = ref(0);

let layout = ref<ElkRoot | null>(null);

const centerToHighlighted = () => {
    nextTick(() => {
        if (!containerRef.value) {
            return;
        }
        const element = document.getElementById(props.highlightedNode.toString());
        const elementDims = element?.getBoundingClientRect();
        const containerDims = containerRef.value?.getBoundingClientRect();
        containerRef.value.scrollTop =
            containerRef.value.scrollTop + (elementDims?.top || 0) -
            (containerDims?.top || 0)
            - (containerRef.value?.parentElement?.clientHeight || 0) / 2
            + (elementDims?.height || 0);
        containerRef.value.scrollLeft =
            containerRef.value.scrollLeft + (elementDims?.left || 0) -
            (containerDims?.left || 0)
            - (containerRef.value?.parentElement?.clientWidth || 0) / 2
            + (elementDims?.width || 0) / 2;
    });
};

const settings = useYukiSettings();

const downloadSvg = () => {
    function triggerDownload(imgURI: string) {
        var evt = new MouseEvent('click', {
            view: window,
            bubbles: false,
            cancelable: true
        });

        var a = document.createElement('a');
        a.setAttribute('download', `mission_chain.png`);
        a.setAttribute('href', imgURI);
        a.setAttribute('target', '_blank');

        a.dispatchEvent(evt);
    }

    var canvas = document.createElement('canvas');
    canvas.width = canvasWidth.value;
    canvas.height = canvasHeight.value;
    var ctx = canvas.getContext('2d');
    if (!ctx) {
        return;
    }
    if (!svgRef.value) {
        return;
    }
    if (unref(settings.isDarkTheme)) {
        ctx.fillStyle = "rgb(31, 41, 55)";
    } else {
        ctx.fillStyle = "rgb(243, 244, 246)";
    }
    ctx?.fillRect(0, 0, canvas.width, canvas.height);
    var data = (new XMLSerializer()).serializeToString(svgRef.value);
    var DOMURL = window.URL || window.webkitURL || window;

    var img = new Image();
    var svgBlob = new Blob([data], { type: 'image/svg+xml;charset=utf-8' });
    var url = DOMURL.createObjectURL(svgBlob);

    img.onload = function () {
        ctx?.drawImage(img, 0, 0);
        DOMURL.revokeObjectURL(url);

        var imgURI = canvas
            .toDataURL('image/png')
            .replace('image/png', 'image/octet-stream');

        triggerDownload(imgURI);
    };

    img.src = url;
};

let pos = { top: 0, left: 0, x: 0, y: 0 };

const mouseMove = (e: MouseEvent) => {
    const dx = e.clientX - pos.x;
    const dy = e.clientY - pos.y;

    // Scroll the element
    if (!containerRef.value) {
        return;
    }
    containerRef.value.scrollTop = pos.top - dy;
    containerRef.value.scrollLeft = pos.left - dx;
};

useWindowEvent('mouseup', () => {
    window.removeEventListener('mousemove', mouseMove);
});

onUnmounted(() => {
    window.removeEventListener('mousemove', mouseMove);
});

const mouseDown = (e: MouseEvent) => {
    pos = {
        left: containerRef.value?.scrollLeft || 0,
        top: containerRef.value?.scrollTop || 0,
        x: e.clientX,
        y: e.clientY,
    };

    window.addEventListener('mousemove', mouseMove);
};

const layoutChanged = (r: ElkRoot) => {
    canvasHeight.value = r.height || 0;
    canvasWidth.value = r.width || 0;
    layout.value = r;

    if (props.highlightedNode) {
        centerToHighlighted();
    }
};

let id = ref("magical");

if(props.graphData) {
    layoutChanged(props.graphData);
} else if(props.nodes && props.edges) {
useLayout({
    nodes: props.nodes, edges: props.edges, direction: 'DOWN', maxHeight: 0, maxWidth: 0, onLayoutChange: layoutChanged
});
}

watch(props, () => {
    if(props.graphData) {
    layoutChanged(props.graphData);
}
});

</script>