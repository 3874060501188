<template>
  <div
    class="text-center"
    :class="[
      inline ? 'flex justify-center items-center gap-0.5' : '',
      disabled ? 'pointer-events-none opacity-60' : '',
    ]"
  >
    <label
      for="custom-input-number"
      class="dark:text-gray-200 font-semibold"
      :class="{ 'w-full': !inline }"
    >
      <slot />
    </label>
    <div class="flex flex-row w-full relative">
      <button
        :disabled="disabled"
        v-if="!noButtons"
        @click="decrement"
        class="number-input-button rounded-l focus:z-30 dark:border-transparent"
        :class="[backgroundClass, backgroundHoverClass].join(' ')"
      >
        <span class="m-auto text-xl font-thin">−</span>
      </button>
      <input
        type="number"
        class="rounded-none flex-grow text-center font-semibold text-md hover:text-black focus:text-black md:text-basecursor-default flex items-center z-20 dark:text-gray-200"
        :class="backgroundClass"
        :style="{ width: inputWidth }"
        :disabled="disabled"
        name="custom-input-number"
        :min="min"
        :max="max"
        :value="value"
        @change="updateInput"
      />
      <button
        :disabled="disabled"
        v-if="!noButtons"
        @click="increment"
        class="rounded-r number-input-button focus:z-30 dark:border-transparent"
        :class="[backgroundClass, backgroundHoverClass].join(' ')"
      >
        <span class="m-auto text-xl font-thin">+</span>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, watch } from "vue";

export default defineComponent({
  name: "NumberInput",
  props: {
    value: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
    min: {
      type: [Number, String],
    },
    max: {
      type: [Number, String],
    },
    inline: {
      type: Boolean,
    },
    background: {
      type: String,
    },
    backgroundHover: {
      type: String,
    },
    noButtons: {
      type: Boolean,
    },
  },
  emits: ["update:value"],
  setup(props, { emit }) {
    const clampedValue = Math.min(
      Math.max(props.value, Number(props.min || Number.MIN_SAFE_INTEGER)),
      Number(props.max || Number.MAX_SAFE_INTEGER)
    );

    const backgroundClass =
      props.background || "bg-gray-50 border border-gray-300 dark:bg-gray-600";

    const backgroundHoverClass =
      props.backgroundHover || "hover:bg-gray-200 dark:hover:bg-gray-900";

    watch(props, () => {
      const clampedValue = Math.min(
        Math.max(props.value, Number(props.min || Number.MIN_SAFE_INTEGER)),
        Number(props.max || Number.MAX_SAFE_INTEGER)
      );

      if (clampedValue != props.value) {
        emit("update:value", clampedValue);
      }
    });

    const inputWidth = `${(props.max ? props.max.toString().length : 0) + 2}ch`;

    if (props.value != clampedValue) {
      emit("update:value", clampedValue);
    }
    return { backgroundClass, backgroundHoverClass, inputWidth };
  },
  methods: {
    updateInput(e: Event) {
      const v = Number((e.target as HTMLInputElement).value);
      const vC = Math.min(
        Math.max(v, Number(this.min || Number.MIN_SAFE_INTEGER)),
        Number(this.max || Number.MAX_SAFE_INTEGER)
      );
      this.$emit("update:value", vC);
      (e.target as HTMLInputElement).value = vC.toString();
    },
    increment() {
      if (this.max && this.value == this.max) {
        return;
      }
      this.$emit("update:value", this.value + 1);
    },
    decrement() {
      if (this.min && this.value == this.min) {
        return;
      }
      this.$emit("update:value", this.value - 1);
    },
  },
});
</script>

<style scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
  min-width: none;
  max-width: 100px;
}

.custom-number-input input:focus {
  outline: none !important;
}

.custom-number-input button:focus {
  outline: none !important;
}

.number-input-button {
  @apply h-full w-12 cursor-pointer;
}
</style>