<template>
  <span class="flex items-center">Grade:</span>
  <span class="flex flex-wrap min-h-8 rounded">
    <text-radio id="ShipTest" label="All" :pick="filter" value="-1" @update:pick="updatePick" />
    <text-radio
      id="ShipTest"
      class="px-2"
      label="G1"
      :pick="filter"
      value="1"
      @update:pick="updatePick"
    />
    <text-radio
      id="ShipTest"
      class="px-2"
      label="G2"
      :pick="filter"
      value="2"
      @update:pick="updatePick"
    />
    <text-radio
      id="ShipTest"
      class="px-2"
      label="G3"
      :pick="filter"
      value="3"
      @update:pick="updatePick"
    />
    <text-radio
      id="ShipTest"
      class="px-2"
      label="G4"
      :pick="filter"
      value="4"
      @update:pick="updatePick"
    />
    <text-radio
      id="ShipTest"
      class="px-2"
      label="G5"
      :pick="filter"
      value="5"
      @update:pick="updatePick"
    />
  </span>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import TextRadio from "../ui/TextRadio.vue";

export default defineComponent({
  name: "GradeFilterGroup",
  components: {
    TextRadio,
  },
  methods: {
    updatePick(e: any) {
      this.$emit("update:filter", e);
    },
  },
  props: {
    filter: {
      type: [String, Number],
      required: true,
    },
  },
});
</script>
