<template>
  {{ name }}
  <span class="ml-auto font-bold tabular-nums text-right">
    {{
      typeof value === "string"
        ? value
        : Array.isArray(value)
          ? value
            .map((v) => percent ? formatPercentageNumber(Number(v)) : formatDecimalNumber(Number(v))
            )
            .join(" | ")
          : percent ? formatPercentageNumber(Number(value)) : formatDecimalNumber(Number(value))
    }}
  </span>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import { useI18n } from "vue-i18n";
import { formatDecimalNumber, formatPercentageNumber } from "~/lib/formatters";

export default defineComponent({
  name: "StatLine",
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: [Number, Array, String],
      // required: true,
    },
    percent: {
      type: Boolean,
    },
  },
  setup() {
    const { t } = useI18n();

    return { t, formatDecimalNumber, formatPercentageNumber };
  },
});
</script>
