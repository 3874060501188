<template>
  <div>
    <div class="relative">
      <button
        ref="buttonRef"
        @click="toggleDropdown"
        class="relative w-full input-bg input-focus pl-1 pr-10 text-left cursor-default sm:text-sm flex flex-wrap gap-x-1 items-center min-h-8"
      >
        <span class="block truncate text-gray-400" v-if="selectedItems.length === 0">Placeholder</span>
        <span
          v-for="tag in selectedItems"
          :key="tag"
          class="inline-flex items-center py-0.5 my-1 pl-2 pr-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-700"
        >
          {{ itemLabel(tag) }}
          <button
            @click.prevent.stop="removeTag(tag)"
            type="button"
            class="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500"
          >
            <span class="sr-only">Remove small option</span>
            <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
              <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
            </svg>
          </button>
        </span>
        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
      </button>

      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ul
          ref="optionsRef"
          v-if="showOptions"
          class="absolute z-50 mt-1 w-full input-bg shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
        >
          <template as="template" v-for="option in options" :key="option.id">
            <li
              :class="[
                'group hover:text-white hover:bg-indigo-600 cursor-default select-none relative py-2 pl-3 pr-9',
              ]"
              @click="addTag(option)"
            >
              <span
                :class="[
                  isItemSelected(option) ? 'font-semibold' : 'font-normal',
                  'block truncate',
                ]"
              >{{ option[label] }}</span>

              <span
                v-if="isItemSelected(option)"
                :class="[
                  'group-hover:text-white',
                  'text-indigo-600',
                  'dark:text-indigo-500',
                  'absolute inset-y-0 right-0 flex items-center pr-4',
                ]"
              >
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </li>
          </template>
        </ul>
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, defineComponent, PropType, watch, toRefs } from "vue";
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import { CheckIcon, SelectorIcon } from "@heroicons/vue/solid";

import { useWindowEvent } from "~/hooks/useWindowEvent";
import { dom } from "~/utils/dom";

export default defineComponent({
  components: {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    SelectorIcon,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    trackBy: {
      type: String,
      default: "id",
    },
    label: {
      type: String,
      default: "name",
    },
    options: {
      type: Object as PropType<Array<any>>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const buttonRef = ref(null);
    const optionsRef = ref(null);

    const { modelValue } = toRefs(props);

    const selectedItems = ref([...(props.modelValue as Array<any>)]);

    watch(modelValue, () => {
      selectedItems.value = [...(modelValue.value as Array<any>)];
    });

    const removeTag = (item: any) => {
      const newItems = selectedItems.value.filter((e) => e !== item);
      emit("update:modelValue", newItems);
    };
    const addTag = (item: any) => {
      if (
        selectedItems.value.find((e) => e === item[props.trackBy]) !== undefined
      ) {
        removeTag(item[props.trackBy]);
        return;
      }
      emit("update:modelValue", [...selectedItems.value, item[props.trackBy]]);
    };

    const showOptions = ref(false);

    useWindowEvent(
      "click",
      (event) => {
        let target = event.target as HTMLElement;
        let active = document.activeElement;

        if (!showOptions.value) return;
        if (dom(buttonRef)?.contains(target)) return;

        if (!dom(optionsRef)?.contains(target)) {
          event.preventDefault();
          event.stopPropagation();
          showOptions.value = false;
        }
        if (active !== document.body && active?.contains(target)) return; // Keep focus on newly clicked/focused element
        if (!event.defaultPrevented)
          dom(buttonRef)?.focus({ preventScroll: true });
      },
      true
    );

    const toggleDropdown = () => {
      showOptions.value = !showOptions.value;
    };

    const isItemSelected = (item: any) => {
      if (
        selectedItems.value.find((e) => e === item[props.trackBy]) !== undefined
      )
        return true;

      return false;
    };

    const itemLabel = (item: any) => {
      return props.options.find((e) => e?.[props.trackBy] == item)?.[props.label] ?? "undefined";
    };

    return {
      selectedItems,
      itemLabel,
      addTag,
      removeTag,
      toggleDropdown,
      showOptions,
      buttonRef,
      optionsRef,
      isItemSelected,
    };
  },
});
</script>