<template>
  <div class="flex h-8 items-center">
    <Menu as="div" class="relative inline-block text-left">
      <MenuButton
        class="
          flex
          items-center
          justify-center
          hover:text-gray-900
          dark:hover:text-gray-400
          focus:outline-none
        "
      >
        <span class="sr-only">Sort options</span>
        <SelectorIcon class="h-6 w-6" />
        <span v-if="activeOption" :style="{ minWidth: labelStyle }">{{
          activeOption[label]
        }}</span>
        <!-- <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6"
        viewBox="0 0 24 24"
        fill-rule="evenodd"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
        stroke="currentColor"
      >
        <path d="M3 20h6m-6-4h9m-9-4h12" stroke-width="2" />
        <path d="M3 8h15M3 4h18" stroke-miterlimit="1.5" stroke-width="2" />
      </svg> -->
        <!-- <icon-button :icon="CollectionIcon"
        ><span class="hidden xs:block">Warp Range</span></icon-button
      > -->
        <!-- <SelectorIcon class="h-6 w-6"/> -->
        <!-- <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-4 w-4"
        viewBox="0 0 24 24"
        fill-rule="evenodd"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
        stroke="currentColor"
      >
        <path d="M3 20h6M3 16h9M3 12h12M3 8h15M3 4h18" stroke-width="2" />
      </svg> -->
      </MenuButton>
      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <MenuItems
          class="
            origin-top-right
            absolute
            right-0
            mt-2
            w-56
            z-50
            rounded-md
            shadow-lg
            bg-gray-50
            dark:bg-gray-600
            ring-1 ring-black ring-opacity-5
            focus:outline-none
          "
        >
          <div class="py-1">
            <MenuItem
              v-slot="{ active }"
              v-for="option in options"
              :key="option[trackBy]"
            >
              <button
                @click="selectOption(option)"
                :class="[
                  'w-full text-left',
                  active ? 'bg-gray-100 text-gray-900' : '',
                  'block px-4 py-2 text-sm',
                ]"
              >
                {{ option[label] }}
              </button>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>
    <span
      class="
        ml-2
        flex
        items-center
        gap-2
        hover:text-gray-900
        dark:hover:text-gray-400
        select-none
      "
      @click="flipSort"
    >
      <SortAscendingIcon class="h-6 w-6" v-if="asc" />
      <SortDescendingIcon class="h-6 w-6" v-else />
      <!-- <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24px"
    viewBox="0 0 24 24"
    width="24px"
    fill="currentColor"
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z" />
  </svg> -->
    </span>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from "vue";

import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";

import {
  SortAscendingIcon,
  SortDescendingIcon,
  CollectionIcon,
  SelectorIcon,
} from "@heroicons/vue/solid";
import IconButton from "./IconButton.vue";

export default defineComponent({
  name: "ListSortControl",
  components: {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    SortAscendingIcon,
    SortDescendingIcon,
    IconButton,
    CollectionIcon,
    SelectorIcon,
  },
  props: {
    asc: {
      type: Boolean,
    },
    sortBy: {
      type: Number,
    },
    options: {
      type: Object as PropType<Array<any>>,
      required: true,
    },
    trackBy: {
      type: String,
      default: "id",
    },
    label: {
      type: String,
      default: "label",
    },
  },
  setup(props, { emit }) {
    const flipSort = () => {
      emit("update:asc", !props.asc);
    };

    const selectOption = (option: any) => {
      emit("update:sortBy", option[props.trackBy]);
    };

    const activeOption = computed(() => {
      return props.options.find((x) => x[props.trackBy] === props.sortBy);
    });

    const labelStyle = computed(() => {
      if (props.options.length === 0) {
        return "0ch";
      }
      let max = props.options[0][props.label].length;
      for (let i = 1; i < props.options.length; ++i) {
        const v = props.options[i][props.label].length;
        if (v > max) {
          max = v;
        }
      }
      return `${max}ch`;
    });

    return { flipSort, selectOption, activeOption, labelStyle, CollectionIcon };
  },
});
</script>
