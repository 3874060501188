<template>
    <g :style="style" :id="props.node.id">
        <router-link :to="`/missions/${props.node.id}`" class="p-2 stroke-1 hover:stroke-2">
            <rect
                tabindex="-1"
                fill="currentColor"
                :color="bgColor"
                :stroke="props.highlighted ? 'rgb(55, 48, 163)' : 'rgb(129, 140, 248)'"
                :height="props.node.height"
                :width="props.node.width"
                rx="2"
                ry="2"
            ></rect>
            <template v-if="props.node.labels.length == 1">
                <g fill="currentColor">
                    <title>{{ props.node.labels[0].originalText || props.node.labels[0].text }}</title>
                    <text
                        v-if="props.node.labels.length == 1"
                        :x="props.node.width / 2"
                        :y="props.node.height / 2"
                        :color="textColor"
                        dominant-baseline="middle"
                        text-anchor="middle"
                    >{{ props.node.labels[0].text }}</text>
                </g>
            </template>
            <g
                v-else
                v-for="label in props.node.labels"
                fill="currentColor"
                :transform="`translate(${label.x}, ${label.y})`"
            >
                <title>{{ label.originalText || label.text }}</title>
                <text
                    v-if="props.node.labels.length == 1"
                    :x="props.node.width / 2"
                    :y="props.node.height / 2"
                    :color="textColor"
                    dominant-baseline="middle"
                    text-anchor="middle"
                >{{ label.text }}</text>
            </g>
        </router-link>
    </g>
</template>

<script setup lang="ts">
import { computed, unref } from 'vue';
import { useYukiSettings } from '~/stores/settings';

const props = defineProps<{
    node: any,
    highlighted?: boolean,
}>();

const style = computed(() => {
    return {
        "transform": `translateX(${props.node.x}px) translateY(${props.node.y}px)`,
    }
});

const settings = useYukiSettings();

const textColor = computed(() => {

    if (unref(settings.isDarkTheme)) {
        return props.highlighted ? 'rgb(17, 24, 39)' : 'rgb(249, 250, 251)';
    } else {
        return props.highlighted ? 'rgb(249, 250, 251)' : 'rgb(17, 24, 39)';
    }
});

const bgColor = computed(() => {
    if (unref(settings.isDarkTheme)) {
        return props.highlighted ? 'rgb(96, 165, 250)' : 'rgb(17, 24, 39)';
    } else {
        return props.highlighted ? 'rgb(96, 165, 250)' : 'rgba(229, 231, 235, 0.6)';
    }

});

</script>

<style scoped>
text {
    font-size: 14px;
}
</style>