import { ref, Ref, watch } from "@vue/runtime-core";
import MiniSearch from "minisearch";
import { nextTick, onMounted } from "vue";

export interface ItemSearchItem {
  id: any;
  __search_text: string;
  __searh_textAlternate?: string;
}

export default class ItemSearch<T> {
  private items: Map<any, ItemSearchItem & T> = new Map();

  constructor(
    fields: string[] = ["text", "textAlternate"],
    private readonly applyFilter?: (o: T) => boolean,
    private impl = new MiniSearch({
      fields: fields,
      searchOptions: {
        prefix: true,
        fuzzy: true,
        combineWith: "AND",
        filter: (result) => {
          if (this.applyFilter === undefined) {
            return true;
          }
          return this.applyFilter(this.items.get(result.id) as T);
        },
      },
    })
  ) {
    // fuse: new Fuse([], {
    //   includeScore: true,
    //   findAllMatches: true,
    //   minMatchCharLength: 2,
    //   shouldSort: true,
    //   keys: ["name"],
    // }),
  }

  public SetItems(items: (ItemSearchItem & T)[]) {
    this.items.clear();
    for (const i of items) {
      this.items.set(i.id, i);
    }
    this.impl.removeAll();
    this.impl.addAll(
      items.map((i: any) => {
        const text = i.__search_text ?? i.text ?? i.name;
        return {
          id: i.id,
          text: i.__search_text ?? i.text ?? i.name,
          textAlternate: i.__searh_textAlternate ?? text
            .replaceAll("★", "*")
            .replaceAll("⇴", "")
            .replaceAll("'", ""),
        };
      })
    );
  }

  public Search(term: string, fallback?: () => T[]): T[] {
    if (term.length === 0 && fallback) {
      return fallback().filter(this.applyFilter || (() => true));
    }
    return this.impl
      .search(term)
      .sort((a, b) => b.score - a.score)
      .map((x) => this.items.get(x.id) as T);
  }
}

export function useSearch<F extends { name: string }, I>(
  items: Ref<Array<ItemSearchItem & I>>,
  filter: Ref<string>,
  fields?: string[],
  filterFunc?: (item: I) => boolean,
  fallback?: () => Array<I>
): Ref<Array<I>> {
  const search = new ItemSearch<I>(fields, filterFunc);

  search.SetItems(items.value);

  const filteredItems: Ref<Array<I>> = ref([]);

  const filterItems = () => {
    filteredItems.value = search.Search(filter.value, fallback);
  };

  watch(items, (newItems) => {
    search.SetItems(newItems);
    filterItems();
  });

  watch(filter, filterItems);

  filterItems();
  return filteredItems;
}
