<template>
  <nav class="dark:bg-gray-800 bg-gray-100 shadow">
    <div class="mx-2 sm:mx-8">
      <div class="mx-auto max-w-screen-lg">
        <div class="relative flex items-center justify-between h-12">
          <div>
            <router-link to="/" class="text-blue font-bold">
              <img class="w-8 h-full" :src="Logo" />
            </router-link>
          </div>
          <div class="flex items-center justify-center">
            <button class="mr-2" @click="share" v-if="canShare">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"
                />
              </svg>
            </button>
            <LanguageSwitcher class="mr-2" />
            <button class="bg-transparent dark:bg-transparent" @click="toggleDarkTheme">
              <div class="text-indigo-500" v-if="settings.isDarkTheme">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
                  />
                </svg>
              </div>
              <div v-else class="text-yellow-300">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
                  />
                </svg>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <div>
    <div
      class="px-2 sm:px-8 shadow bg-gray-200 bg-opacity-40 dark:bg-gray-800 dark:bg-opacity-70 mb-2"
    >
      <div class="mx-auto max-w-screen-lg py-1 h-8 flex w-full overflow-ellipsis">
        <ol
          v-if="crumbs.length > 0"
          class="list-reset flex items-center text-grey-dark dark:text-grey-200 w-full max-w-screen"
        >
          <li>
            <router-link to="/" class="text-blue font-bold">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                />
              </svg>
            </router-link>
          </li>
          <template v-for="(crumb, index) in crumbs" :key="crumb.to">
            <li>
              <span class="mx-1">/</span>
            </li>
            <li>
              <div class="whitespace-nowrap" v-if="index == crumbs.length - 1">{{ crumb.text }}</div>
              <router-link
                v-else-if="crumbs.length > 2 && index == 0"
                :to="crumb.to"
                class="text-blue font-bold text-sm"
              >
                {{
                  crumb.text[0]
                }}
              </router-link>
              <router-link
                v-else
                :to="crumb.to"
                class="text-blue font-bold text-sm whitespace-nowrap"
              >
                {{
                  crumb.text
                }}
              </router-link>
            </li>
          </template>
        </ol>
      </div>
    </div>
  </div>
</template>


<script setup lang="ts">
import { computed, defineComponent } from "vue";
import { useRoute, useRouter } from "vue-router";

import LanguageSwitcher from "./ui/LanguageSwitcher.vue";

import { itemName as getItemName, ItemType } from "../router";

import Logo from "~/assets/logo.png";
import { uniqBy } from "lodash";
import { useYukiSettings } from "~/stores/settings";


const emits = defineEmits(["toggleDarkTheme"]);

const settings = useYukiSettings();

const toggleDarkTheme = () => {
  emits("toggleDarkTheme");
};

const route = useRoute();

const crumbs = computed(() => {
  let pathArray = route.path.split("/");
  pathArray.shift();

  return uniqBy(route.matched, 'path').map((m) => {
    let n = m.path;
    for (const k in route.params) {
      n = n.replace(`:${k}`, route.params[k] as any);
    }

    const parts = n.split("/");
    const lastPart = parts[parts.length - 1];

    return {
      path: n,
      to: n,
      text: (m.meta.breadCrumb ?? getItemName(lastPart, m.meta.type as ItemType)) as string,
    };
  });
});

const itemName = (ids: string) => {
  return getItemName(ids, route.meta.type as ItemType);
};

const share = () => {
  navigator
    .share({
      title: document.title,
      url: window.location.href,
    })
    .catch(() => { });
};
const canShare = !!window.navigator.share;

</script>
