<template>
  <div
    :class="[
      'vs-slider-warning',
      `vs-slider-dark`,
      'mx-6',
      'py-4',
      { disabledx: disabled },
    ]"
    :style="{ touchAction: 'none' }"
    class="relative w-full mx-3"
  >
    <div
      ref="slider"
      :disabled="disabled"
      class="relative block w-full h-2 z-10 dark:bg-gray-600 bg-gray-200 rounded slider-bar-touch-target"
      type="button"
      @touchstart.passive="activeFocus($event)"
      @mousedown.prevent="activeFocus($event)"
    >
      <span
        v-for="(tick, index) in countTicks"
        :key="index"
        :style="{ left: `${tickOffset(tick)}%` }"
        class="absolute h-full w-1 top-0 bg-gray-300 opacity-50 dark:bg-gray-700"
      ></span>
    </div>
    <slider-circle
      :disabled="disabled"
      :class="{
        hasTransition: effect,
        isEquals: isEquals,
        changeValue: changeValue,
        isEndValue: value == max,
      }"
      class="vs-circle-slider vs-circles-slider vs-slider--circles vs-slider--circle"
      :style="{ left: `${leftX}%` }"
      @activeFocus="activeFocus($event)"
    />
    <slider-circle
      v-if="Array.isArray(value)"
      :disabled="disabled"
      :class="{
        hasTransition: effect,
        isEquals: isEquals,
        changeValue: changeValue,
      }"
      :style="{ left: `${leftTwo}%` }"
      class="vs-circle-slider-two vs-circles-slider vs-slider--circles vs-slider--circle-two"
      @activeFocus="activeFocus($event), (two = true)"
    />
  </div>
</template>

<script lang="ts">
import { ref, computed, watch, toRefs, PropType, defineComponent } from "vue";
import SliderCircle from "./SliderCircle.vue";

export default defineComponent({
  components: {
    SliderCircle,
  },
  props: {
    value: {
      required: true,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    max: {
      default: 100,
      type: [Number, String],
    },
    min: {
      default: 0,
      type: [Number, String],
    },
    ticks: {
      default: false,
      type: Boolean,
    },
    step: {
      default: 1,
      type: [Number, String],
    },
    stepDecimals: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["update:value", "change"],
  setup(props, { emit }) {
    const slider = ref(null as unknown as HTMLButtonElement);

    const isEquals = computed(() => {
      return Array.isArray(props.value)
        ? props.value[0] == props.value[1]
        : false;
    });

    const toDecimal = (value: number) => {
      return parseFloat(value.toFixed(1));
    };

    const countTicks = computed(() => {
      return Array.from(
        { length: Number(props.max) - Number(props.min) - 1 },
        (_, i) => i + 1
      );
    });

    const changeValue = ref(false);

    const changePosition = () => {
      const min = Number(props.min);
      const max = Number(props.max);
      let leftX;
      let leftTwo = 0;
      if (Array.isArray(props.value)) {
        leftX = ((props.value[1] - min) / (max - min)) * 100;
        leftTwo = ((props.value[0] - min) / (max - min)) * 100;
      } else {
        leftX = (((props.value as number) - min) / (max - min)) * 100;
      }

      return [leftX, leftTwo];
    };

    const [iX, iTwo] = changePosition();

    const leftX = ref(iX);
    const leftTwo = ref(iTwo);
    const two = ref(false);
    const actived = ref(false);
    const effect = ref(false);

    const tickOffset = (index: number): number => {
      const min = Number(props.min);
      const max = Number(props.max);
      const step = Number(props.step);

      let lengthPerStep = 100 / ((max - min) / step);
      return index * lengthPerStep;
    };
    const activeFocus = (evt: MouseEvent | TouchEvent) => {
      actived.value = true;
      clickSlider(evt as MouseEvent);

      window.addEventListener("mousemove", mouseMovex);
      window.addEventListener("mouseup", removeEvents);
      window.addEventListener("touchmove", mouseMovex, { passive: true });
      window.addEventListener("touchend", removeEvents);
    };
    const mouseMovex = (evt: MouseEvent | TouchEvent) => {
      const sliderV = slider.value;
      if (!sliderV) {
        return;
      }
      let leftx;
      /*
       * change position left circle and bar
       */
      if (window.TouchEvent && evt instanceof TouchEvent) {
        leftx =
          evt.targetTouches[0].clientX - sliderV.getBoundingClientRect().left;
      } else {
        leftx =
          (evt as MouseEvent).clientX - sliderV.getBoundingClientRect().left;
      }
      if (Math.sign(leftx) == -1) {
        leftx = 0;
      } else if (leftx > sliderV.clientWidth) {
        leftx = sliderV.clientWidth;
      }
      changeLeft(leftx);
    };

    const removeEvents = () => {
      two.value = actived.value = false;
      window.removeEventListener("mouseup", removeEvents);
      window.removeEventListener("mousemove", mouseMovex);
      window.removeEventListener("touchmove", mouseMovex);
      window.removeEventListener("touchend", removeEvents);
    };

    const changeLeft = (leftx: number) => {
      const isArray = Array.isArray(props.value);
      const sliderV = slider.value;
      const min = Number(props.min);
      const max = Number(props.max);
      const step = Number(props.step);

      let percentX = (leftx / sliderV.clientWidth) * 100;

      const lengthPerStep = 100 / ((max - min) / step);
      const steps = Math.round(percentX / lengthPerStep);
      let val = steps * lengthPerStep * (max - min) * 0.01 + min;
      if (isNaN(val)) {
        val = min;
      }

      val = props.stepDecimals ? toDecimal(val) : Math.round(val);
      let leftV = 0;
      if (val > max) {
        val = max;
        leftV = 100;
      } else {
        leftV = steps * lengthPerStep;
      }
      if (two.value) {
        leftTwo.value = leftV;
      } else {
        leftX.value = leftV;
      }

      let newValue;
      if (isArray) {
        let valueNew = val;
        if (val == max) {
          valueNew = max;
        }

        let vals: any = props.value;
        let minR = Math.round((leftTwo.value / 100) * (max / step)) * step;
        let maxR = Math.round((leftX.value / 100) * (max / step)) * step;
        if (two.value) {
          if (minR < maxR) {
            newValue = [valueNew, vals[1]];
          } else if (minR > maxR) {
            newValue = [vals[0], valueNew];
          } else {
            newValue = [valueNew, valueNew];
          }
        } else {
          if (minR > maxR) {
            newValue = [valueNew, vals[1]];
          } else if (minR < maxR) {
            newValue = [vals[0], valueNew];
          } else {
            newValue = [valueNew, valueNew];
          }
        }
      } else {
        newValue = val;
      }
      if (
        (isArray &&
          Array.isArray(props.value) &&
          (props.value[0] != (newValue as any)[0] ||
            props.value[1] != (newValue as any)[1])) ||
        (!isArray && props.value != newValue)
      ) {
        emit("update:value", newValue);
      }
    };

    const clickSlider = (evt: MouseEvent) => {
      let sliderV = slider.value;
      let leftx;
      if (window.TouchEvent && evt instanceof TouchEvent) {
        leftx =
          evt.targetTouches[0].clientX - sliderV.getBoundingClientRect().left;
      } else {
        leftx =
          (evt as MouseEvent).clientX - sliderV.getBoundingClientRect().left;
      }
      effect.value = true;
      setTimeout(() => {
        effect.value = false;
      }, 200);
      let percentX = Math.round((leftx / sliderV.clientWidth) * 100);
      if (Array.isArray(props.value)) {
        if (
          Math.abs(percentX - leftX.value) > Math.abs(percentX - leftTwo.value)
        ) {
          two.value = true;
        } else {
          two.value = false;
        }
      }

      changeLeft(leftx);
    };

    const clampValue = () => {
      const min = Number(props.min);
      const max = Number(props.max);

      if (Array.isArray(props.value)) {
        const [oMin, oMax] = props.value;
        if (oMin < Number(props.min) || oMax > Number(props.max)) {
          const nMin = Math.min(Math.max(oMin, min), max);
          const nMax = Math.min(Math.max(oMax, min), max);
          emit("update:value", [nMin, nMax]);
        }
      } else if (
        (props.value as number) < min ||
        (props.value as number) > max
      ) {
        const clampedValue = Math.min(
          Math.max(props.value as number, min, max)
        );
        emit("update:value", clampedValue);
      }
    };

    const { value } = toRefs(props);

    watch(value, (newValue) => {
      if (!actived.value) {
        const [iX, iTwo] = changePosition();
        leftX.value = iX;
        leftTwo.value = iTwo;
      }
      changeValue.value = true;
      setTimeout(() => {
        changeValue.value = false;
      }, 300);
      emit("change", newValue);
    });
    setTimeout(() => {
      clampValue();
    });

    return {
      activeFocus,
      countTicks,
      isEquals,
      changeValue,
      effect,
      two,
      tickOffset,
      leftX,
      leftTwo,
      slider,
    };
  },
});
</script>


<style>
.con-vs-slider {
  width: 100%;
  position: relative;
  left: 0;
  margin: 16px 0;
  display: block;
  min-width: 300px;
  z-index: 100;
}
.con-vs-slider.disabledx {
  opacity: 0.4;
  cursor: default;
}
.con-vs-slider.disabledx button {
  cursor: default !important;
  pointer-events: none;
}
.vs-slider {
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background: #f0f0f0;
  position: relative;
  cursor: pointer;
  border: 0;
  margin: 0;
  display: block;
  overflow: hidden;
}
.vs-slider-line-one {
  width: 20px;
  height: 100%;
  top: 0;
  position: absolute;
  left: 0;
  z-index: 1;
}
.vs-slider-line-one.hasTransition {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.vs-slider-line-efect {
  width: 0;
  height: 100%;
  top: 0;
  position: absolute;
  left: 0;
  -webkit-transition: opacity 0.3s ease, width 0.3s ease;
  transition: opacity 0.3s ease, width 0.3s ease;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
}
.vs-slider-line-efect:not(.run-effect) {
  opacity: 0 !important;
  width: 0 !important;
}
.vs-slider-line-efect.run-effect {
  width: 100%;
  -webkit-animation: example 0.3s ease;
  animation: example 0.3s ease;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}
.vs-slider--tick {
  position: absolute;
  left: 100px;
  background: #d2d2d2;
  width: 4px;
  height: 100%;
  top: 0;
}
.vs-slider--tick.isEnd {
  -webkit-transform: translate(-100%);
  transform: translate(-100%);
}

.vs-slider--circles.isEquals.vs-circle-slider-two {
  border-radius: 50% 50% 0 0;
  transform: translate(-50%, -100%) scale(0.9) !important;
}
.vs-slider--circles.isEquals.vs-circle-slider {
  border-radius: 0 0 50% 50%;
  transform: translate(-50%, -25%) scale(0.9) !important;
}
.vs-slider--circles.changeValue:active {
  border-width: 6px !important;
}
.vs-slider--circles.changeValue:active .text-circle-slider {
  -webkit-transform: translate(-50%, calc(-100% + -19px)) !important;
  transform: translate(-50%, calc(-100% + -19px)) !important;
}
.vs-slider--circles.isEndValue {
  border-radius: 50% 0 0 50% !important;
}

.vs-slider--circles:active .text-circle-slider {
  opacity: 1;
  -webkit-transform: translate(-50%, calc(-100% + -13px)) scale(1) rotate(0deg);
  transform: translate(-50%, calc(-100% + -13px)) scale(1) rotate(0deg);
  visibility: visible;
}
.vs-slider--circle-text {
  color: #fff;
  position: absolute;
  padding: 2px 5px;
  display: block;
  border-radius: 5px;
  -webkit-transform: translate(-50%, -20%) scale(0.3);
  transform: translate(-50%, -20%) scale(0.3);
  top: 0;
  left: 50%;
  font-size: 0.75rem;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 1000;
}
.vs-slider--circle-text span {
  margin-left: 2px;
}
.vs-slider--circle-text i {
  font-size: 0.8rem;
  margin-left: 2px;
}
.vs-slider--circle-text:after {
  content: "";
  width: 6px;
  height: 6px;
  display: block;
  position: absolute;
  background: inherit;
  left: 50%;
  bottom: -3px;
  -webkit-transform: translate(-50%) rotate(45deg);
  transform: translate(-50%) rotate(45deg);
}

.vs-slider--circle.hasTransition {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.vs-slider--circle-two.hasTransition {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
@media (pointer: coarse) {
  .slider-bar-touch-target::after {
    top: -15px;
    left: -15px;
    right: -15px;
    bottom: -15px;
    content: "";
    position: absolute;
  }
}
@media (pointer: fine) {
  .slider-bar-touch-target::after {
    top: -8px;
    left: -8px;
    right: -8px;
    bottom: -8px;
    content: "";
    position: absolute;
  }
}
</style>