import { ref, onMounted, nextTick } from "vue";


export function useFilterNameHack() {
    const filterName = ref("");
    const searchField = ref(null as unknown as HTMLInputElement);
    onMounted(() => {
        filterName.value = searchField.value.value;
        nextTick(() => {
            if (!window.matchMedia("(pointer: coarse)").matches) {
                searchField.value.focus();
            }
        })
    });

    return { filterName, searchField };
}