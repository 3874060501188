<template>
  <button type="button" class="icon-button" @click="$emit('click')">
    <slot />
    <component :is="icon" class="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" />
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "IconButton",
  props: {
    icon: {
      type: [Object, Function],
      required: true,
    },
  },
});
</script>
<style>
.icon-button {
  @apply inline-flex
            items-center
            px-3
            py-2
            ml-auto
            shadow-sm
            text-sm
            leading-4
            font-medium
            rounded
            text-white
            bg-indigo-600
            hover:bg-indigo-700
            focus:outline-none;
}
</style>
