<template>
  <nice-increase-display
    :base="formatBuffValue(buffValue)"
    :increase="formatBuffValue(buffIncrease)"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { formatGroupedNumber, formatPercentageNumber } from "~/lib/formatters";
import { Buff } from "~/lib/yuki/models";
import NiceIncreaseDisplay from "./NiceIncreaseDisplay.vue";

export default defineComponent({
  components: { "nice-increase-display": NiceIncreaseDisplay },
  name: "BuffDisplay",
  props: {
    buff: {
      type: Object as PropType<Buff>,
      required: true,
    },
    level: {
      type: Number,
      required: true,
    },
  },
  computed: {
    buffValue(): number {
      return this.buff.values[this.level].value;
    },
    buffIncrease(): number {
      return (
        this.buff.values[this.level].value -
        (this.buff.values[this.level - 1]?.value ?? 0) ??
        this.buff.values[this.level].value
      );
    },
  },
  methods: {
    formatBuffValue(value: number): string {
      if (this.buff.show_percentage || this.buff.value_is_percentage) {
        return formatPercentageNumber(value);
      } else {
        return formatGroupedNumber(value);
      }
    },
  },
});
</script>
