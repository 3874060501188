<template>
  <div
    :disabled="disabled"
    type="button"
    class="cursor-pointer absolute transition transform top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 hover:scale-125 rounded-full border-2 active:border-4 hover:bg-gray-700 dark:hover:bg-gray-900 border-blue-400 darkbg-white bg-gray-600 w-6 h-6 z-20 slider-thumb-touch-target"
    @touchstart.passive="activeFocus($event)"
    @mousedown.prevent.stop="activeFocus($event)"
  >
    <!-- <span class="text-circle-slider vs-slider--circle-text">
        {{ valueCircle1 }}
    </span>-->
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "SliderCircle",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["activeFocus"],
  setup(props, { emit }) {
    const activeFocus = (evt: any) => {
      emit("activeFocus", evt);
    };

    return { activeFocus };
  },
});
</script>

<style scoped>
@media (pointer: coarse) {
  .slider-thumb-touch-target::after {
    top: -15px;
    left: -15px;
    right: -15px;
    bottom: -15px;
    content: "";
    position: absolute;
  }
}
@media (pointer: fine) {
  .slider-thumb-touch-target::after {
    top: -8px;
    left: -8px;
    right: -8px;
    bottom: -8px;
    content: "";
    position: absolute;
  }
}
</style>
