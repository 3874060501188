import { useYukiDataStore } from "~/stores";
import { isStaticTranslation, useYukiTranslationStore } from "~/stores/translations";
import { BuildCost, ResourceRewardRange, Reward, ShipComponentWeapon, ShipDetailComponent } from "./models";


export function calculateShipStats(components: ShipDetailComponent[]) {
    const WeaponDPR = (weapon: ShipComponentWeapon) => {
        const AVGDmg =
            (weapon.minimum_damage + weapon.maximum_damage) * 0.5;
        const Shots = weapon.shots;
        const ReloadingTime = weapon.cool_down;
        const CriticalChance = weapon.crit_chance;
        const CriticalDamage = weapon.crit_modifier;
        return (
            ((AVGDmg * Shots) / ReloadingTime) * (CriticalChance * CriticalDamage) +
            (AVGDmg * Shots) / ReloadingTime
        );
    };
    const AttackRatingForComponent = (weapon: ShipComponentWeapon) => {
        return (
            WeaponDPR(weapon) +
            weapon.accuracy * 0.5 +
            weapon.penetration * 0.5 +
            weapon.modulation * 0.5
        );
    };

    const stats: any = {
        attack: 0,
        defense: 0,
        health: 0,

        // Health Parts
        shield_hp: 0,
        hull_hp: 0,

        // Attack Parts
        dpr: 0,
        armor_piercing: 0,
        shield_piercing: 0,
        accuracy: 0,
        critical_chance: 0,
        critical_damage: 0,

        // Defense Parts
        armor: 0,
        absorption: 0,
        dodge: 0,
    };

    let weapon_count = 0;
    for (const component of components) {
        if (component.data.tag === "Shield") {
            stats.shield_hp = component.data.hp;
            stats.absorption = component.data.absorption;
        } else if (component.data.tag === "Cargo") {
        } else if (component.data.tag === "Armor") {
            stats.armor = component.data.plating;
            stats.hull_hp = component.data.hp;
        } else if (component.data.tag === "Weapon") {
            const dpr = WeaponDPR(component.data);
            const rating = AttackRatingForComponent(
                component.data
            );
            stats.attack += rating;
            stats.dpr += dpr;
            const attack_part = component.data;
            stats.accuracy += attack_part.accuracy;
            stats.shield_piercing += attack_part.modulation;
            stats.armor_piercing += attack_part.penetration;
            stats.critical_chance += attack_part.crit_chance;
            stats.critical_damage += attack_part.crit_modifier;
            weapon_count++;
        } else if (component.data.tag === "Impulse") {
            stats.dodge = component.data.dodge;
        }
    }

    const RoundAwayFromZero = (startValue: number, digits: number) => {
        var decimalValue = 0;
        digits = digits || 0;
        startValue *= Math.pow(10, digits + 1);
        decimalValue = Math.floor(startValue) - Math.floor(startValue / 10) * 10;
        startValue = Math.floor(startValue / 10);
        if (decimalValue >= 5) {
            startValue += 1;
        }
        startValue /= Math.pow(10, digits);
        return startValue;
    }

    stats.health = stats.hull_hp * 0.5 + stats.shield_hp * 0.5;
    stats.defense =
        stats.armor * 5 + stats.dodge * 5 + stats.absorption * 5;
    stats.accuracy /= weapon_count;
    stats.armor_piercing /= weapon_count;
    stats.shield_piercing /= weapon_count;
    stats.critical_chance /= weapon_count;
    stats.critical_damage /= weapon_count;

    stats.accuracy = RoundAwayFromZero(stats.accuracy, 0);
    stats.armor_piercing = RoundAwayFromZero(stats.armor_piercing, 0);
    stats.shield_piercing = RoundAwayFromZero(stats.shield_piercing, 0);
    stats.critical_chance = RoundAwayFromZero(stats.critical_chance, 3);
    stats.critical_damage = RoundAwayFromZero(stats.critical_damage, 3);

    stats.dpr = RoundAwayFromZero(stats.dpr, 0);
    stats.attack = RoundAwayFromZero(stats.attack, 0);
    stats.defense = RoundAwayFromZero(stats.defense, 0);
    stats.health = RoundAwayFromZero(stats.health, 0);
    stats.strength = stats.attack + stats.defense + stats.health;

    return stats;
}

export function sortCosts<T>(resources: (T & { resource_id: number })[]): (T & { resource_id: number })[] {
    const dataStore = useYukiDataStore();
    return resources.sort((a, b) => {
        const aResource = dataStore.resources.get(a.resource_id);
        const bResource = dataStore.resources.get(b.resource_id);
        const aIndex = aResource?.sorting_index ?? 0;
        const bIndex = bResource?.sorting_index ?? 0;
        const index = aIndex - bIndex;
        if (index === 0) {
            // Secondary sortying by rarity :)
            const rarity = (aResource?.rarity ?? 0) - (bResource?.rarity ?? 0);
            if (rarity == 0) {
                // Last resort is grad
                return (aResource?.grade || 0) - (bResource?.grade || 0);
            }
            return rarity;
        }
        return index;
    });
}

export function sortRewards(resources: Reward[]): Reward[] {
    return sortCosts(resources);
}

export function sortRewardRanges(resources: ResourceRewardRange[]): ResourceRewardRange[] {
    return sortCosts(resources);
}

export function getStaticTranslation<T extends true | false = true>(category: string, id: string | number, key: string, fallback?: T): T extends true ? string : string | undefined {
    const translationStore = useYukiTranslationStore();
    const translation = translationStore.data.get(category)?.get(String(id))?.get(key);
    if (!translation) {
        if (!isStaticTranslation(category, id, key)) {
            translationStore.fetchDynamicTranslations(category, [id]);
        }
        if (fallback === undefined || fallback) {
            return `${category}_${id}_${key}`;
        }
        return undefined as any;
    }
    return translation;
}