import { defineStore } from 'pinia';

export const useYukiSettings = defineStore({
    id: 'yukiSettings',
    state: () => {
        console.log(window?.matchMedia('(prefers-color-scheme: dark)')?.matches);
        let s = {};
        try {
            s = JSON.parse(window.localStorage.getItem('settings') || '');
        } catch { };

        s = Object.assign({
            darkTheme: window?.matchMedia('(prefers-color-scheme: dark)')?.matches,
            forcedTheme: false,
        }, s);
        return s as { forcedTheme: boolean, darkTheme: boolean; };
    },
    getters: {
        isDarkTheme(state) {
            return (state.forcedTheme && state.darkTheme) || (!state.forcedTheme && window?.matchMedia('(prefers-color-scheme: dark)')?.matches);
        }
    },
    actions: {
        setForcedTheme(force: boolean) {
            this.forcedTheme = force;
            try {
                window.localStorage.setItem('settings', JSON.stringify({
                    darkTheme: this.darkTheme,
                    forcedTheme: this.forcedTheme,
                }));
            } catch { }
        },
        setDarkTheme(dark: boolean) {
            this.darkTheme = dark;
            try {
                window.localStorage.setItem('settings', JSON.stringify({
                    darkTheme: this.darkTheme,
                    forcedTheme: this.forcedTheme,
                }));
            } catch { }
        }
    }
});