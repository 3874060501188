import {
    elkLayout,
    CanvasDirection,
    ElkCanvasLayoutOptions
} from './elkLayout';
import { EdgeData, NodeData } from '../types';
import { ref, Ref } from '@vue/runtime-core';
import { watchEffect } from 'vue';
import { isEqual } from 'lodash';

export interface ElkRoot {
    x?: number;
    y?: number;
    width?: number;
    height?: number;
    children?: any[];
    edges?: any[];
    direction?: CanvasDirection;
}

export interface LayoutProps {
    maxHeight: number;
    maxWidth: number;
    nodes: NodeData[];
    edges: EdgeData[];
    layoutOptions?: ElkCanvasLayoutOptions;
    direction: CanvasDirection;
    onLayoutChange?: (layout: ElkRoot) => void;
}

export interface LayoutResult {
    /**
     * ELK Layout object.
     */
    layout: ElkRoot;

    /**
     * Ref to container div.
     */
    containerRef: Ref<HTMLDivElement | null>;

    /**
     * Height of the svg.
     */
    canvasHeight?: number;

    /**
     * Width of the svg.
     */
    canvasWidth?: number;

    /**
     * Width of the container div.
     */
    containerWidth?: number;

    /**
     * Height of the container div.
     */
    containerHeight?: number;

    /**
     * Center the canvas to the viewport.
     */
    centerCanvas?: () => void;

    /**
     * Fit the canvas to the viewport.
     */
    fitCanvas?: () => void;
}

export const useLayout = ({
    nodes = [],
    edges = [],
    direction,
    layoutOptions = {},
    onLayoutChange
}: LayoutProps) => {
    let cRef = ref<HTMLDivElement>();
    // const { observe, width, height } = useDimensions<HTMLDivElement>();
    let layout = ref<ElkRoot | null>(null);
    const canvasHeight = 0;
    const canvasWidth = 0;

    watchEffect(() => {
        const promise = elkLayout(nodes, edges, {
            'elk.direction': direction,
            ...layoutOptions
        });

        promise
            .then(result => {
                if (!isEqual(layout.value, result)) {
                    layout.value = result;
                    onLayoutChange?.(result);
                }
            })
            .catch(err => {
                if (err.name !== 'CancelError') {
                    console.error('Layout Error:', err);
                }
            });

        return () => promise.cancel();
    });

    return {
        containerRef: cRef,
        canvasHeight,
        canvasWidth,
        layout,
    } as LayoutResult;
};