<template>
  <div class="filter-box-wrapper">
    <div class="filter-box">
      <div class="filter-box-header">
        {{ label }}
        <icon-button
          class="h-8"
          :icon="AdjustmentsIcon"
          @click="$emit('reset')"
        >
          {{ t("filters.reset") }}
        </icon-button>
      </div>
      <div class="filter-box-content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, reactive, defineComponent } from "vue";

import { AdjustmentsIcon } from "@heroicons/vue/solid";
import IconButton from "../ui/IconButton.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "FilterBox",
  components: {
    IconButton,
  },
  props: {
    label: {
      type: String,
    },
  },
  emits: ["reset"],
  setup() {
    const { t } = useI18n();

    return { t, AdjustmentsIcon };
  },
});
</script>
<style>
.filter-box > *:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.filter-box > *:last-child {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.filter-box-wrapper {
  @apply md:sticky
      flex-shrink-0
      shadow
      rounded
      overflow-visible
      w-full
      h-fit
      max-h-screen
      gap-2
      z-20;
}

.filter-box {
  @apply flex flex-wrap dark:bg-gray-700 dark:bg-opacity-70 rounded;

  &-content {
    @apply w-full p-2 flex flex-wrap gap-2;
  }

  &-header {
    @apply flex items-center content-between w-full p-2 bg-gray-200 dark:bg-gray-800;
  }
}
</style>
