import { defineStore } from 'pinia';
import { Building, Consumable, Hostile, Mission, Officer, Research, Resource, Ship, System, Galaxy, MissionWarpUnlocks } from '~/lib/yuki/models';

import { markRaw } from '@vue/reactivity';

import { YukiApi } from "./api";

export const useYukiDataStore = defineStore({
    id: 'yukiData',
    state: () => ({
        resources: new Map<number, Resource>(),
        ships: [] as Array<Ship>,
        officers: [] as Array<Officer>,
        buildings: [] as Array<Building>,
        researches: [] as Array<Research>,
        systems: [] as Array<System>,
        hostiles: [] as Array<Hostile>,
        consumables: [] as Array<Consumable>,
        missions: [] as Array<Mission>,
        galaxy: [] as Galaxy,
        mission_warp_unlocks: [] as MissionWarpUnlocks,
        lsfv: "",
        data_fetch_retries: 0,
    }),

    getters: {
        galaxyConnections: (state) => {
            return state.galaxy.flatMap(g => g.connections);
        }
    },

    actions: {
        async fetchVersion() {
            try {
                const version = await YukiApi.get<string>("/version", { responseAs: "text" });
                YukiApi.options.query.version = version;
                this.lsfv = version;
                try {
                    window.localStorage.setItem('dataVersion', version);
                } catch (e) {

                }
                this.fetchData();
            } catch (e) {
                console.error("Failed to fetch version from API", e);
            }
        },
        async fetchData() {
            if (this.lsfv === '') {
                await this.fetchVersion();
                return;
            }

            let allCachable = true;

            const loadInto = (path: string, f: (e: any) => void) => {
                return YukiApi.get<Response>(path, { responseAs: 'response' }).then(e => {
                    allCachable = allCachable && e.headers.get("X-Is-Cachable") === 'true';
                    e.json().then(e => {
                        f(e.map((x: any) => markRaw(x)))
                    });
                });
            };

            try {
                let promises = [];
                promises.push(loadInto('/ship', (e) => { this.ships = e }));
                promises.push(loadInto('/officer', (e) => { this.officers = e }));
                promises.push(loadInto('/building', (e) => { this.buildings = e }));
                promises.push(loadInto('/research', (e) => { this.researches = e }));
                promises.push(loadInto('/system', (e) => { this.systems = e }));
                promises.push(loadInto('/hostile', (e) => { this.hostiles = e }));
                promises.push(loadInto('/consumable', (e) => { this.consumables = e }));
                promises.push(YukiApi.get<Response>('/mission', { responseAs: 'response' }).then(e => {
                    allCachable = allCachable && e.headers.get("X-Is-Cachable") === 'true';
                    return e.json();
                }).then(e => {
                    if (e.all_rewards) {
                        this.missions = e.missions.map((x: any) => markRaw(x));
                    } else {
                        this.missions = e.map((x: any) => markRaw(x));
                    }
                }));
                promises.push(loadInto('/galaxy', (e) => { this.galaxy = e }));
                promises.push(loadInto('/galaxy/mission_unlocks', (e) => { this.mission_warp_unlocks = e }));
                promises.push(YukiApi.get<Resource[]>('/resource').then(e => {
                    const m = new Map();
                    for (const resource of e) {
                        m.set(resource.id, markRaw(resource));
                    }
                    this.resources = m;
                }));

                await Promise.allSettled(promises);
            } catch (error) {
                this.data_fetch_retries = this.data_fetch_retries + 1;
                if (this.data_fetch_retries > 10) {
                    console.error("Failed to load data from API", error);
                    return;
                }
                console.error("Failed to load data from API, retrying", error);
                this.lsfv = "";
                // Reschedule data fetch in 5 seconds
                setTimeout(() => {
                    this.fetchVersion();
                }, 5 * 1000);
            }

            if (!allCachable) {
                // Servers are likely deploying right now, try again in a few seconds
                // We still have loaded all the new data, hoping things don't go wrong :sweat_smile:
                // Reschedule data fetch in 5 seconds
                setTimeout(() => {
                    this.fetchVersion();
                }, 5 * 1000);
            }
        }
    }
});
