<template>
  <div v-if="stats.strength" class="flex gap-2 text-lg mb-2">
    <span>{{ t("ui.power") }}:</span>
    <span class="font-bold">{{ formatDecimalNumber(stats.strength) }}</span>
  </div>
  <div class="flex flex-wrap gap-4">
    <div class="sm:w-fit w-full flex flex-col flex-wrap gap-2">
      <h4 class="font-bold pb-2 mb-auto">Attack ({{ formatDecimalNumber(stats.attack) }})</h4>
      <stat-grid class="gap-x-4 flex flex-col w-full md:w-fit min-w-stat-container">
        <stat-line name="⚪ DPR" :value="stats.dpr" />
        <stat-line name="⚪ Armor Piercing" :value="stats.armor_piercing" />
        <stat-line name="⚪ Accuracy" :value="stats.accuracy" />
        <stat-line name="⚪ Shield Piercing" :value="stats.shield_piercing" />
        <stat-line name="⚪ Critical Chance" :value="stats.critical_chance" percent />
        <stat-line name="⚪ Critical Damage" :value="stats.critical_damage" percent />
      </stat-grid>
    </div>
    <div class="sm:w-fit w-full flex flex-col flex-wrap gap-2">
      <h4 class="font-bold b-2 mb-auto">Defense ({{ formatDecimalNumber(stats.defense) }})</h4>
      <stat-grid class="flex flex-col w-full md:w-fit min-w-stat-container">
        <stat-line name="⚪ Armor" :value="stats.armor" />
        <stat-line name="⚪ Shield Deflection" :value="stats.absorption" />
        <stat-line name="⚪ Dodge" :value="stats.dodge" />
      </stat-grid>
    </div>
    <div class="sm:w-fit w-full flex flex-col flex-wrap gap-2">
      <h4 class="font-bold pb-2 mb-auto">Health ({{ formatDecimalNumber(stats.health) }})</h4>
      <stat-grid class="flex flex-col w-full md:w-fit min-w-stat-container">
        <stat-line name="⚪ Shield Health" :value="stats.shield_hp" />
        <stat-line name="⚪ Hull Health" :value="stats.hull_hp" />
      </stat-grid>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { formatDecimalNumber } from "~/lib/formatters";
import StatGrid from "./StatGrid.vue";
import StatLine from "./StatLine.vue";

export default defineComponent({
  components: { StatLine, StatGrid },
  props: {
    stats: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();

    return { t, formatDecimalNumber };
  },
});
</script>
