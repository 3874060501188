<template>
  <span class="w-full block dark:text-gray-50">Tree:</span>
  <span class="flex flex-wrap min-h-8 rounded">
    <text-radio id="FactionFilter" label="All" value="-1" :pick="filter" @update:pick="updatePick" />
    <text-radio
      class="whitespace-nowrap w-fit flex-grow p-1"
      id="ShipTest"
      label="Combat"
      value="1870147103"
      :pick="filter"
      @update:pick="updatePick"
    />
    <text-radio
      class="whitespace-nowrap w-fit flex-grow p-1"
      id="ShipTest"
      label="Station"
      value="1766645516"
      :pick="filter"
      @update:pick="updatePick"
    />
    <text-radio
      class="whitespace-nowrap w-fit flex-grow p-1"
      id="ShipTest"
      label="Galaxy"
      value="1056678826"
      :pick="filter"
      @update:pick="updatePick"
    />
    <text-radio
      class="whitespace-nowrap w-fit flex-grow p-1"
      id="ShipTest"
      label="Away Teams"
      value="2024220584"
      :pick="filter"
      @update:pick="updatePick"
    />
    <text-radio
      class="whitespace-nowrap w-fit flex-grow p-1"
      id="ShipTest"
      label="Outlaw"
      value="3374361850"
      :pick="filter"
      @update:pick="updatePick"
    />
    <text-radio
      class="whitespace-nowrap w-fit flex-grow p-1"
      id="ShipTest"
      label="Territory"
      value="1454228576"
      :pick="filter"
      @update:pick="updatePick"
    />
    <text-radio
      v-if="hasStarships"
      class="whitespace-nowrap w-fit flex-grow p-1"
      id="ShipTest"
      label="Starships"
      value="1868126734"
      :pick="filter"
      @update:pick="updatePick"
    />
  </span>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import TextRadio from "../ui/TextRadio.vue";

interface ResearchTreeFilter {
  combat: boolean;
  station: boolean;
  galaxy: boolean;
  awayTeams: boolean;
  outlaw: boolean;
  territory: boolean;
}

export default defineComponent({
  name: "ResearchTreeFilterGroup",
  components: {
    TextRadio,
  },
  props: {
    filter: {
      type: [Number, String],
      required: true,
    },
    hasStarships: {
      type: Boolean,
    }
  },
  methods: {
    updatePick(e: any) {
      this.$emit("update:filter", e);
    },
  },
});
</script>
