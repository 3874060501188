<template>
  <input
    class="px-2 h-8"
    placeholder="Filter"
    :value="filter"
    @input="handleInput"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "TextFilterGroup",
  methods: {
    handleInput(e: any) {
      this.$emit("update:filter", e.target.value);
    },
  },
  props: {
    filter: {
      type: String,
      required: true,
    },
  },
});
</script>
