import { useYukiTranslationStore } from "~/stores";

export function abbreviateNumber(value: number): string {
    const translationStore = useYukiTranslationStore();
    try {
        //  TODO(alexander): Make this a setting :)
        if (Math.abs(value) < 100000) {
            return translationStore.formatters.decimalNumber.format(value);
        }
        return translationStore.formatters.abbrvNumber.format(value);
    } catch {
        return value.toString();
    }
}

export function formatDecimalNumber(value: number): string {
    const translationStore = useYukiTranslationStore();
    try {
        return translationStore.formatters.decimalNumber.format(value);
    } catch {
        return value.toString();
    }
}

export function formatPercentageNumber(value: number, decimal: boolean = false): string {
    const translationStore = useYukiTranslationStore();
    try {
        return decimal ? translationStore.formatters.percentNumber2.format(value) : translationStore.formatters.percentNumber.format(value);
    } catch {
        return value.toString();
    }
}

export function formatGroupedNumber(value: number): string {
    const translationStore = useYukiTranslationStore();
    try {
        return translationStore.formatters.groupedNumber.format(value);
    } catch {
        return value.toString();
    }
}