<template>
  <div class="relative flex items-start">
    <div class="flex items-center h-5">
      <input
        :checked="modelValue"
        @change="updateValue($event)"
        :id="`cb-${id}`"
        :aria-describedby="`cb-desc-${id}`"
        :name="name"
        type="checkbox"
        class="focus:ring-indigo-500 h-5 w-5 text-indigo-600 dark:text-indigo-600 border-gray-300 rounded"
      />
    </div>
    <div class="ml-3 text-sm select-none">
      <label :for="`cb-${id}`" class="font-medium dark:text-current">
        {{
          label
        }}
      </label>
      <p v-if="description" :id="`cb-desc-${id}`" class="text-gray-500">{{ description }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { useId } from "~/hooks/useId";

export default defineComponent({
  name: "Checkbox",
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    name: {
      type: String,
    },
    label: {
      type: String,
      required: true,
    },
    description: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const id = useId();

    const updateValue = (e: Event) => {
      emit("update:modelValue", (e.target as HTMLInputElement)?.checked);
    };

    return { id, updateValue };
  },
});
</script>

<style>
</style>
