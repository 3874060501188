<template>
  <div class="relative">
    <span class="flex cursor-pointer">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
        />
      </svg>
      <svg
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path
          class="heroicon-ui"
          d="M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z"
        ></path>
      </svg>
    </span>
    <select
      class="
        cursor-pointer
        absolute
        bg-transparent
        text-xl
        opacity-0
        top-0
        left-0
        w-12
      "
      ref="select"
      @change="languageChange"
    >
      <option class="dark:bg-gray-900" data-content="en">🇺🇸 English</option>
      <option class="dark:bg-gray-900" data-content="fr">🇫🇷 Français</option>
      <option class="dark:bg-gray-900" data-content="it">🇮🇹 Italian</option>
      <option class="dark:bg-gray-900" data-content="de">🇩🇪 Deutsch</option>
      <option class="dark:bg-gray-900" data-content="es">🇪🇸 Español</option>
      <option class="dark:bg-gray-900" data-content="ru">🇷🇺 русский</option>
      <option class="dark:bg-gray-900" data-content="pt">🇵🇹 Português</option>
      <option class="dark:bg-gray-900" data-content="ja">🇯🇵 日本語</option>
      <option class="dark:bg-gray-900" data-content="ko">🇰🇷 한국어</option>
    </select>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from "vue";

import { useI18n } from "vue-i18n";
import { useYukiTranslationStore } from "~/stores";

export default defineComponent({
  name: "LanguageSwitcher",
  setup() {
    const translationStore = useYukiTranslationStore();

    const select = ref(null as unknown as HTMLSelectElement);
    const selectedLanguage = ref(translationStore.selectedLanguage);
    const i18n = useI18n();

    const languageChange = (e: any) => {
      const selected = e.target.options[e.target.options.selectedIndex];
      i18n.locale.value = selected.dataset.content;
      translationStore.changeLanguage(selected.dataset.content);
      selectedLanguage.value = translationStore.selectedLanguage;
    };

    const selectedLanguageDisplay = computed(() => {
      selectedLanguage.value;
      return selectedLanguage.value.toUpperCase();
    });

    onMounted(() => {
      if (!select.value) {
        return;
      }
      for (let i = 0; i < select.value.options.length; ++i) {
        const option = select.value.options[i];
        if (option.dataset.content == selectedLanguage.value) {
          select.value.value = option.value;
          break;
        }
      }
    });
    return {
      select,
      selectedLanguage,
      languageChange,
      selectedLanguageDisplay,
    };
  },
});
</script>
