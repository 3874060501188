<template>
  <list-item>
    <template v-slot:image>
      <officer-thumb width="64" height="64" :id="item.art_id" />
    </template>
    <template v-slot:name>
      <div class="inline-flex items-center truncate">
        <rarity-label :rarity="item.rarity"></rarity-label>
        <yuki-text class="font-bold" skey="name" :id="item.id" category="officers" />
      </div>
    </template>
    <template v-slot:sub>
      <yuki-text skey="name" :id="item.synergy_id" category="officers_synergy" />
    </template>
  </list-item>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import ListItem from "../ui/ListItem.vue";

import OfficerThumb from "../thumbs/OfficerThumb.vue";
import RarityLabel from "../generic/RarityLabel.vue";
import { Officer } from "~/lib/yuki/models";

export default defineComponent({
  components: {
    ListItem,
    OfficerThumb,
    RarityLabel,
  },
  props: {
    item: {
      type: Object as PropType<Officer>,
      required: true,
    },
  },
  setup() {},
});
</script>
