<template>
  <img :src="thumbImage" />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import IndependentIcon from "~/assets/icons/factions/independent.png";
import FederationIcon from "~/assets/icons/factions/federation.png";
import RomulanIcon from "~/assets/icons/factions/romulan.png";
import KlingonIcon from "~/assets/icons/factions/klingon.png";
import AndorianIcon from "~/assets/icons/factions/andorian.png";
import RogueIcon from "~/assets/icons/factions/rogue.png";
import AugmentIcon from "~/assets/icons/factions/augment.png";

export default defineComponent({
  name: "FactionThumb",
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const icon = () => {
      if (props.id == -1) {
        return IndependentIcon;
      } else if (props.id == 2064723306) {
        return FederationIcon;
      } else if (props.id == 4153667145) {
        return KlingonIcon;
      } else if (props.id == 669838839) {
        return RomulanIcon;
      } else if (props.id == 2720916152) {
        return AndorianIcon;
      } else if (props.id == 2143656960) {
        return RogueIcon;
      } else if (props.id == 2113010081) {
        return AugmentIcon;
      }
      console.warn("Unknown faction thumb", props.id);
      return IndependentIcon;
    };

    return {
      thumbImage: icon(),
    };
  },
});
</script>
