<template>
  <div class="generic-card">
    <slot name="header" />
    <span
      class="
        flex
        justify-center
        w-full
        border-b border-opacity-25
        dark:border-opacity-25 dark:border-gray-50
        border-gray-900
        pb-2
      "
      v-if="title"
      >{{ title }}</span
    >

    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "GenericCard",
  props: {
    title: {
      type: String,
    },
  },
});
</script>

