<template>
  <div class="px-2 py-3 flex items-center justify-between sm:px-4">
    <div class="flex-1 flex justify-between sm:hidden">
      <a
        href="#"
        class="relative inline-flex items-center px-2 py-2 rounded-l-md bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-500 text-sm font-medium text-gray-500 hover:bg-gray-50 dark:hover:bg-gray-900"
        @click.prevent="changePage(prevPage)"
      >
        <span class="sr-only">Previous</span>
        <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
      </a>
      <a
        href="#"
        class="relative inline-flex items-center px-2 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-500 text-sm font-medium text-gray-500 hover:bg-gray-50 dark:hover:bg-gray-900"
        @click.prevent="changePage(1)"
      >
        <span class="sr-only">First</span>
        <ChevronDoubleLeftIcon class="h-5 w-5" aria-hidden="true" />
      </a>
      <a
        href="#"
        @click.prevent="changePage(currentPage)"
        class="bg-white z-10 hover:bg-gray-50 dark:hover:bg-gray-900 md:inline-flex relative items-center px-4 py-2 border text-sm font-medium bg-indigo-50 dark:bg-indigo-900 border-indigo-500 text-indigo-600 dark:text-indigo-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
      >{{ currentPage }}</a>
      <a
        href="#"
        class="relative inline-flex items-center px-2 py-2 bg-white border border-gray-300 dark:border-gray-500 dark:bg-gray-700 text-sm font-medium text-gray-500 hover:bg-gray-50 dark:hover:bg-gray-900"
        @click.prevent="changePage(totalPages)"
      >
        <span class="sr-only">Last</span>
        <ChevronDoubleRightIcon class="h-5 w-5" aria-hidden="true" />
      </a>
      <a
        href="#"
        class="relative inline-flex items-center px-2 py-2 rounded-r-md bg-white border border-gray-300 dark:border-gray-500 dark:bg-gray-700 text-sm font-medium text-gray-500 hover:bg-gray-50 dark:hover:bg-gray-900"
        @click.prevent="changePage(nextPage)"
      >
        <span class="sr-only">Next</span>
        <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
      </a>
    </div>
    <div class="sm:flex-1 sm:flex sm:items-center sm:justify-between hidden sm:visible">
      <div>
        <nav
          class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
          aria-label="Pagination"
        >
          <a
            href="#"
            class="relative inline-flex items-center px-2 py-2 rounded-l-md bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-500 text-sm font-medium text-gray-500 hover:bg-gray-50 dark:hover:bg-gray-900"
            @click.prevent="changePage(prevPage)"
          >
            <span class="sr-only">Previous</span>
            <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
          </a>
          <a
            href="#"
            @click.prevent="changePage(1)"
            v-if="hasFirst()"
            class="bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-500 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium"
          >1</a>
          <span
            class="relative inline-flex items-center px-4 py-2 bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-500 text-sm font-medium text-gray-700 dark:text-gray-500 border"
            v-if="hasFirst()"
          >...</span>
          <a
            href="#"
            @click.prevent="changePage(page)"
            v-for="page in pages"
            class="bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-500 text-gray-500 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-900 md:inline-flex relative items-center px-4 py-2 border text-sm font-medium text-center"
            :class="{
              'z-10 bg-indigo-50 dark:bg-indigo-900 border-indigo-500 text-indigo-600 dark:text-indigo-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium':
                currentPage == page,
            }"
            :key="page"
          >{{ page }}</a>
          <span
            class="relative inline-flex items-center px-4 py-2 bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-500 text-sm font-medium text-gray-700 dark:text-gray-500 border"
            v-if="hasLast()"
          >...</span>
          <a
            href="#"
            @click.prevent="changePage(totalPages)"
            v-if="hasLast()"
            class="bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-500 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium"
          >{{ totalPages }}</a>
          <a
            href="#"
            class="relative inline-flex items-center px-2 py-2 rounded-r-md bg-white border border-gray-300 dark:border-gray-500 dark:bg-gray-700 text-sm font-medium text-gray-500 hover:bg-gray-50 dark:hover:bg-gray-900"
            @click.prevent="changePage(nextPage)"
          >
            <span class="sr-only">Next</span>
            <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
          </a>
        </nav>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  ChevronLeftIcon,
  ChevronDoubleLeftIcon,
  ChevronRightIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/vue/solid";
import { computed, defineComponent, toRefs, watch } from "@vue/runtime-core";

export default defineComponent({
  components: {
    ChevronLeftIcon,
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
    ChevronRightIcon,
  },
  props: {
    currentPage: {
      type: Number,
      default: null,
    },
    perPage: {
      type: Number,
      default: 20,
      validator: (value: number) => value > 0,
    },
    totalItems: {
      type: Number,
      default: 0,
      validator: (value: number) => value >= 0,
    },
    limit: {
      type: Number,
      default: 5,
      validator: (value: number) => value >= 0,
    },
    pageRange: {
      type: Number,
      default: 2,
    },
  },
  emits: ["page-changed"],
  setup(props, { emit }) {
    const totalPages = computed((): number => {
      if (props.perPage <= 0) {
        return 0;
      }

      return Math.ceil(props.totalItems / props.perPage);
    });

    const rangeStart = computed((): number => {
      const start = Number(props.currentPage) - props.pageRange;
      const s = Math.min(totalPages.value - props.pageRange * 2, start);
      const r = s > 0 ? s : 1;
      return r;
    });

    const rangeEnd = computed((): number => {
      let end = Number(props.currentPage) + props.pageRange;
      if (props.currentPage <= props.pageRange) {
        end += props.pageRange;
      }
      if (end - rangeStart.value > props.pageRange * 2) {
        end -= end - rangeStart.value - props.pageRange * 2;
      }
      const r = end < totalPages.value ? end : totalPages.value;
      return r;
    });

    const pages = computed((): number[] => {
      let pages = [];
      for (let i = rangeStart.value; i <= rangeEnd.value; i++) {
        pages.push(i);
      }
      return pages;
    });

    const nextPage = computed((): number => {
      return Number(props.currentPage) + 1;
    });

    const prevPage = computed((): number => {
      return Number(props.currentPage) - 1;
    });

    const isPageActive = (page: number): boolean => {
      return page === props.currentPage;
    };

    const hasFirst = (): boolean => {
      return rangeStart.value !== 1;
    };
    const hasLast = (): boolean => {
      return rangeEnd.value < totalPages.value;
    };
    const hasPrev = (): boolean => {
      return Number(props.currentPage) > 1;
    };
    const hasNext = (): boolean => {
      return Number(props.currentPage) < totalPages.value;
    };
    const changePage = (page: number) => {
      if (page > 0 && page <= totalPages.value) {
        emit("page-changed", page);
      }
    };

    const { totalItems } = toRefs(props);

    watch(totalItems, () => {
      if (props.currentPage * props.perPage > props.totalItems) {
        changePage(1);
      }
    });

    return {
      totalPages,
      rangeStart,
      rangeEnd,
      pages,
      nextPage,
      prevPage,
      isPageActive,
      hasFirst,
      hasLast,
      hasPrev,
      hasNext,
      changePage,
    };
  },
});
</script>