<template>
  {{ f(base) }}
  (
  <span class="text-green-500">+{{ f(increase) }}</span>)
</template>

<script setup lang="ts">
import { formatGroupedNumber } from "~/lib/formatters";

const props = defineProps<{
  base: Number | String
  increase: Number | String
}>();

const f = (v: Number | String) => {
  return Number.isInteger(v) ? formatGroupedNumber(Number(v)) : v;
}

</script>